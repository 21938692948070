import React from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import logo from "../../../images/logo.png";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { fetchAssesmentResultDetail } from "./DueTrainingData";
import { useState } from "react";
import moment from "moment/moment";

const TrainingCertificate = () => {
  const location = useLocation();
  const [resultData, setResultData] = useState();

  useEffect(() => {
    if (location?.state?.isAssesmentExist !== false) {
      loadResultDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadResultDetails = () => {
    let params = {
      courseId: location.state.courseId,
      userId: location.state.userData.id,
    };
    fetchAssesmentResultDetail(params, (data, error) => {
      if (error === null) {
        setResultData(data);
      }
    });
  };

  return (
    <Content>
      <div className="wide-sm mx-auto">
        <p className="fs-1 text-center text-dark mb-4 fw-bold">Congratulations</p>
        <p className="text-center fs-4">
          {location?.state?.isAssesmentExist !== false && (
            <>You passed with a score of {resultData?.data?.percentage}%</>
          )}
        </p>
        <div className="bg-white p-4 course-complete-certificate">
          <Card className="border p-4 rounded-0 ">
            <div className="mb-5">
              <p className="fs-3 text-center text-dark m-0 fw-bold">Saunders Training Academy</p>
              <p className="text-center fs-5 text-soft letter-spacing-1">TRAINING CERTIFICATE </p>
            </div>
            <p className="my-5 fs-5 text-dark text-center fw-bold">
              <span className="me-1">{location?.state?.userData?.firstName}</span>
              <span>{location?.state?.userData?.lastName}</span>
            </p>
            <p className="text-center px-5 text-dark mb-5 mt-3">
              Has completed the <span className="fw-bold">{location?.state?.courseDetail?.name}</span> <br />
              course delivered by Saunders training Academy <br /> on
              <span className="fw-bold"> {moment().format("DD MMM YYYY")}</span>
            </p>
            <div className="mt-5">
              <img className="mx-auto d-block" src={logo} alt="" />
            </div>
          </Card>
        </div>
        <p className="mt-2 mb-4 text-center fs-4">You can download your certificate!</p>
        <div className="text-center">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={location?.state?.certificateUrl}
            target="_blank"
            className="btn btn-primary btn-text   px-5"
            type="submit"
          >
            Download certificate
          </a>
        </div>
      </div>
    </Content>
  );
};

export default TrainingCertificate;
