/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import fileIcons, { deleteResource, fetchResource } from "./ResourceData";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/spinner/LoadingSpinner";
import { Storage } from "../services/storage/storage";
import { UserAccountType } from "../utils";

const ResourcesFolder = () => {
  const [resourceData, setResourceData] = useState([]);
  const [modal, setModal] = useState({
    addResource: false,
    video: false,
  });

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editItem, setEditItem] = useState();
  const [itemPerPage] = useState(100);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const user = Storage.getLoggedInUser();
  const navigate = useNavigate();

  // Get current list, pagination
  useEffect(() => {
    if (currentPage !== 0) {
      loadResource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const truncate = function (fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || ". . . . . . ";

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
  };

  function loadResource() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemPerPage,
      },
    };

    fetchResource(params, (data, error) => {
      setIsInitScreen(false);
      if (error === null) {
        setResourceData(data.list ?? []);
        setTotalItems(data.pageInfo?.totalItems ?? 0);
      }
    });
  }
  const removeResource = (item, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item.title} from resources?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, I want!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: item.id,
        };
        deleteResource(params, (data, error) => {
          if (error === null) {
            let list = [...resourceData];
            list.splice(index, 1);
            setResourceData(list);
          }
        });
      }
    });
  };
  const onEditClick = (item) => {
    setEditItem(item);
    setModal({ addResource: true });
  };

  // DATA GET AND CONVERT FOR LOCATION--------->
  const convertLinks = (item) => {
    let links = [];
    item?.link.map((item) => {
      let list = {
        title: item?.title.toString(),
        links: item?.list?.flat(),
      };
      return links.push(list);
    });
    return links;
  };
  const convertFiles = (item) => {
    let groupFiles = [];
    item?.resourceGroup.map((item, index) => {
      let filesArray = [];
      item?.files.map((file) => {
        let fileDetails = file;
        fileDetails.isLoading = false;
        fileDetails.isError = false;
        fileDetails.isOldFile = true;

        let filedetail = {
          id: file.id,
          file: fileDetails,
        };
        filesArray.push(filedetail);
      });
      let list = {
        id: item?.id,
        title: item?.title,
        files: filesArray,
      };
      groupFiles.push(list);
    });
    return groupFiles;
  };

  const onViewClick = (item) => {
    navigate(`${process.env.PUBLIC_URL}/resources`, {
      state: {
        resource: item,
      },
    });
  };

  return (
    <React.Fragment>
      <Head title="Resources" />
      {!isInitScreen && (
        <Content>
          <div className="wide-md mx-auto">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h3" page>
                    Resources
                  </BlockTitle>
                </BlockHeadContent>
                {user?.accountType === UserAccountType.admin && (
                  <BlockHeadContent>
                    <Button
                      color="primary"
                      className="btn-text custom-solid-btn-sm"
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/add-resources`);
                      }}
                    >
                      Add resource
                    </Button>
                  </BlockHeadContent>
                )}
              </BlockBetween>
            </BlockHead>

            <Block>
              <div className="nk-files nk-files-view-list">
                <div className="nk-files-list">
                  {resourceData?.map((item, index) => (
                    <React.Fragment key={`${index}`}>
                      <div className="nk-file-item nk-file cursor-pointer resource-list-base">
                        <div
                          className="nk-file-info  border-0"
                          style={{ width: "100%" }}
                          onClick={(ev) => {
                            ev.preventDefault();
                            onViewClick(item);
                          }}
                        >
                          <div className="nk-file-title">
                            <div className="nk-file-icon">
                              <span className="nk-file-icon-type">{fileIcons["folder"]}</span>
                            </div>
                            <div className="nk-file-name">
                              <div className="nk-file-name-text">
                                <span className="title">
                                  {/* {truncate(item.title, 25)} */}
                                  {item.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-file-actions">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              href="#folder"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                {user?.accountType === UserAccountType.admin ? (
                                  <>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#item"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          navigate(`${process.env.PUBLIC_URL}/add-resources`, {
                                            state: {
                                              resourceData: {
                                                id: item?.id,
                                                title: item?.title,
                                                resourceLink: convertLinks(item),
                                                resourceFile: convertFiles(item),
                                                removeFileIds: [],
                                              },
                                            },
                                          });
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#item"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          removeResource(item, index);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Remove</span>
                                      </DropdownItem>
                                    </li>
                                  </>
                                ) : (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#item"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onViewClick(item);
                                      }}
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>View</span>
                                    </DropdownItem>
                                  </li>
                                )}
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </Block>
          </div>
        </Content>
      )}

      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default ResourcesFolder;
