import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Button,
  DataTable,
  DataTableBody,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import AddChapter from "./AddChapter";
import { CourseWizardContext } from "./CourseWizard";
import { showErrorToast } from "../../../utils";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const CourseChapter = (props) => {
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const { data, updateData } = React.useContext(CourseWizardContext);
  const [toggle, setToggle] = useState(false);

  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  // index
  // eslint-disable-next-line no-extend-native
  Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
  };

  useEffect(() => {
    if ((data?.chapters?.length ?? 0) === 0) {
      updateCourses([
        {
          html: "",
          id: 0,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (item, index) => {
    setSelectedItem(item);
    setSelectedItemIndex(index);
    setModal({ edit: true });
  };

  const updateCourses = (list) => {
    updateData("chapters", list);
  };

  const handleRemove = (value) => {
    let list = (data?.chapters ?? []).filter((item) => item !== value);
    updateCourses(list);
  };

  const onNextClick = () => {
    if ((data?.chapters ?? []).length === 1) {
      setError("Please add at least one chapter");
    } else {
      setError(null);
      props.next();
    }
  };

  const handleDragEnd = (result) => {
    setToggle(false);
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === 0 || destination.index === 0) {
      showErrorToast("You are not allowed to change introduction position.");
      return;
    }
    let list = [...(data?.chapters ?? [])];
    list.move(source.index, destination.index);
    updateCourses(list);
  };

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="align-items-end">
            <BlockHeadContent className=" align-items-center ms-auto">
              <Button color="primary" className="btn-text custom-solid-btn-sm" onClick={() => setModal({ add: true })}>
                Add chapter
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable className={`card-stretch ${toggle ? "mb-70" : ""} `}>
            <DragDropContext
              onDragEnd={handleDragEnd}
              onBeforeDragStart={() => {
                setToggle(true);
              }}
              onDragStart={() => {
                //setToggle(true);
              }}
            >
              <Droppable droppableId="table-body">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} {...provided.dragHandleProps}>
                    <DataTableBody>
                      {(data?.chapters ?? []).length > 0
                        ? (data?.chapters ?? []).map((item, index) => {
                            return (
                              <Draggable
                                draggableId={"" + item.id}
                                key={"id-" + item.id}
                                index={index}
                                // isDragDisabled
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={"border"}
                                    >
                                      <DataTableItem
                                        className={"justify-content-between d-flex align-center container"}
                                      >
                                        <DataTableRow>
                                          <div className="project-info">
                                            <h6 className="title">
                                              <ul>
                                                {index === 0 ? (
                                                  <li key={index}>Introduction</li>
                                                ) : (
                                                  <li key={index}>
                                                    Chapter {index}: {item.title}
                                                  </li>
                                                )}
                                              </ul>
                                            </h6>
                                          </div>
                                        </DataTableRow>
                                        {index === 0 ? (
                                          <DataTableRow className=" text-end">
                                            <span style={{ cursor: "pointer" }}>
                                              <Icon name="pen-alt-fill" onClick={() => handleEdit(item, index)}></Icon>
                                            </span>
                                          </DataTableRow>
                                        ) : (
                                          <DataTableRow className="nk-tb-col-tools text-end">
                                            <ul className="nk-tb-actions gx-1">
                                              <li>
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    tag="a"
                                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                  >
                                                    <Icon name="more-h"></Icon>
                                                  </DropdownToggle>
                                                  <DropdownMenu end>
                                                    <ul className="link-list-opt no-bdr">
                                                      <li>
                                                        <DropdownItem
                                                          tag="a"
                                                          href="#edit"
                                                          onClick={() => handleEdit(item, index)}
                                                        >
                                                          <Icon name="edit"></Icon>
                                                          <span>Edit</span>
                                                        </DropdownItem>
                                                      </li>
                                                      <li>
                                                        <DropdownItem
                                                          tag="a"
                                                          href="#delete"
                                                          onClick={() => handleRemove(item, index)}
                                                        >
                                                          <Icon name="trash"></Icon>
                                                          <span>Remove</span>
                                                        </DropdownItem>
                                                      </li>
                                                    </ul>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </li>
                                            </ul>
                                          </DataTableRow>
                                        )}
                                      </DataTableItem>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })
                        : null}
                    </DataTableBody>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {(data?.chapters ?? []).length === 1 && (
              <div className="text-center my-3">
                <span className="text-silent">You have not added any chapter yet.</span>
                {error && (
                  <div className="text-center">
                    <span className="text-danger">{error}</span>
                  </div>
                )}
              </div>
            )}
          </DataTable>

          <div className="actions clearfix ">
            <ul>
              <li className=" align-items-center ms-auto">
                <Button color="primary" type="submit" className="custom-solid-btn-sm" onClick={() => onNextClick()}>
                  Next
                </Button>
              </li>
              <li>
                <Button color="light" type="button" onClick={props.prev}>
                  Back
                </Button>
              </li>
            </ul>
          </div>
        </Block>
        <AddChapter
          visible={modal.edit || modal.add}
          item={selectedItem}
          isIntro={selectedItemIndex === 0}
          onClosed={() => {
            setModal({ add: false, edit: false });
            setSelectedItem();
            setSelectedItemIndex();
          }}
          onSaved={(object) => {
            let list = [...(data?.chapters ?? [])];
            if (selectedItem && (selectedItemIndex ?? 0) >= 0) {
              selectedItem.html = object.html;
              selectedItem.title = object.title;
              selectedItem.questions = object.questions;
              list[selectedItemIndex] = selectedItem;
            } else {
              list.push({
                html: object.html,
                title: object.title,
                questions: object.questions,
                id: 0,
              });
            }
            updateCourses(list);
          }}
        />
      </Content>
    </React.Fragment>
  );
};
export default CourseChapter;
