import React from "react";
import { useState } from "react";
import { Form, Spinner } from "reactstrap";
import { Button, Col, RSelect, Row } from "../../../../components/Component";
import { uploadCouseCover } from "../TrainingData";
import { CourseWizardContext } from "./CourseWizard";
import { DueOptions, DurationOptions, FrequencyOptions, YesNoOptions } from "../../Roles/RolesData";
import { ErrorText } from "../../../../components/text/Error";

const CourseInfoBasic = (props) => {
  const [isError, setError] = useState(false);
  const { data, updateData } = React.useContext(CourseWizardContext);

  const submitForm = () => {
    let name = data?.courseName?.trim();
    let about = data?.about?.trim();
    let cover = data?.cover;
    let frequency = data?.frequency;
    let dueIn = data?.dueIn;
    let evalutionForm = data?.evalutionForm;
    let isCpd = data?.isCpd;
    let isManualCertificate = data?.isManualCertificate;

    if (
      cover &&
      name &&
      name.length > 0 &&
      about &&
      about.length > 0 &&
      frequency !== undefined &&
      evalutionForm !== undefined &&
      dueIn !== undefined &&
      isCpd !== undefined &&
      isManualCertificate !== undefined
    ) {
      setError(false);
      props.next();
    } else {
      setError(true);
    }
  };

  function uploadCover(file) {
    let data = file;
    data.isUploading = true;
    data.isError = false;
    data.fileId = null;
    updateData("cover", data);
    uploadCouseCover(data, (object, error) => {
      if (error === null) {
        data.fileId = object.id;
      } else {
        data.isError = true;
      }
      data.isUploading = false;
      updateData("cover", data);
    });
  }

  return (
    <div className="arerer">
      <Row>
        <Col md="6">
          <div
            className="form-group"
            htmlFor="files"
            style={{
              backgroundImage: data?.cover
                ? `url(${typeof data.cover === "string" ? data.cover : URL.createObjectURL(data.cover)})`
                : require("../../../../images/avatar/placeholder-image.png"),
            }}
          >
            <div className="form-control-wrap">
              <input
                type="file"
                name="files"
                accept="image/*"
                className="form-control"
                id="files"
                style={{ display: "none" }}
                onChange={(e) => ((e.target.files?.length ?? 0) > 0 ? uploadCover(e.target.files[0]) : null)}
              />
              {isError && data.cover === undefined && <ErrorText />}
            </div>
          </div>
          <div className="form-group text-center">
            <div
              className="form-control-wrap w-100 img-thumbnail mb-3 mx-auto"
              style={{ height: "270px", overflow: "hidden" }}
            >
              <img
                src={
                  data?.cover
                    ? typeof data.cover === "string"
                      ? data.cover
                      : URL.createObjectURL(data.cover)
                    : require("../../../../images/avatar/placeholder-image.png")
                }
                alt={"Selected"}
                style={{
                  height: "270px",
                  width: " 100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <label
              // className="btn"
              htmlFor="files"
              style={{
                color: "#2C75FF",
                fontSize: 14,
                marginBottom: 14,
              }}
            >
              {data?.cover?.isUploading === true ? <Spinner size="sm" color="dark" /> : "Select Photo"}
            </label>
          </div>
        </Col>
        <Col md="6">
          <Form className="content clearfix">
            <div className="form-group">
              <label className="form-label mt-3" htmlFor="course-name">
                Course Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="course-name"
                  className="form-control"
                  name="courseName"
                  onChange={(e) => updateData("courseName", e.target.value)}
                  value={data?.courseName ?? ""}
                />
                {isError && (data?.courseName?.trim() ?? 0) === 0 && <ErrorText />}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                About
              </label>
              <div className="form-control-wrap">
                <textarea
                  id="about"
                  className="form-control"
                  name="about"
                  onChange={(e) => updateData("about", e.target.value)}
                  value={data?.about ?? ""}
                />
                {isError && (data?.about?.trim() ?? 0) === 0 && <ErrorText />}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                Frequency
              </label>
              <RSelect
                options={FrequencyOptions}
                value={data?.frequency}
                onChange={(e) => updateData("frequency", e)}
              />
              {isError && data.frequency === undefined && <ErrorText />}
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                Due In
              </label>
              <RSelect options={DueOptions} value={data?.dueIn} onChange={(e) => updateData("dueIn", e)} />
              {isError && data.dueIn === undefined && <ErrorText />}
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                Duration
              </label>
              <RSelect options={DurationOptions} value={data?.duration} onChange={(e) => updateData("duration", e)} />
              {isError && data.duration === undefined && <ErrorText />}
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                Is CPD Training?
              </label>
              <RSelect options={YesNoOptions} value={data?.isCpd} onChange={(e) => updateData("isCpd", e)} />
              {isError && data.isCpd === undefined && <ErrorText />}
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                Include Evalution Form?
              </label>
              <RSelect
                options={YesNoOptions}
                value={data?.evalutionForm}
                onChange={(e) => updateData("evalutionForm", e)}
              />
              {isError && data.evalutionForm === undefined && <ErrorText />}
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="about">
                Manual Certificate Upload Required?
              </label>
              <RSelect
                options={YesNoOptions}
                value={data?.isManualCertificate}
                onChange={(e) => updateData("isManualCertificate", e)}
              />
              {isError && data.isManualCertificate === undefined && <ErrorText />}
            </div>
            <div className="actions clearfix">
              <ul className="justify-content-end">
                <li>
                  <Button color="primary" type="button" className="custom-solid-btn-sm" onClick={() => submitForm()}>
                    Next
                  </Button>
                </li>
              </ul>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CourseInfoBasic;
