/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import {
  Block,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  RSelect,
} from "../../components/Component";
import Head from "../../layout/head/Head";
import { DueOptions, FrequencyOptions } from "../dashboard/Roles/RolesData";
import { AddTrainingWizardContext } from "./TrainingWizard";
import moment from "moment/moment";
import { assignCourseToStaff } from "./StaffData";
import { Spinner } from "reactstrap";

const AssignTraining = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { data, updateData } = useContext(AddTrainingWizardContext);

  const changeCompleteDate = (index, date, object) => {
    let list = [...data.courseListData];
    const newOption = {
      ...object,
      completedAt: moment(date).format("DD-MM-YYYY"),
    };
    list[index] = newOption;
    updateData("courseListData", list);
  };

  const changeDueDate = (index, date, object) => {
    let list = [...data.courseListData];
    const newOption = {
      ...object,
      maxDueAt: moment(date).format("DD-MM-YYYY"),
    };
    list[index] = newOption;
    updateData("courseListData", list);
  };

  const changeDueIn = (index, e, object) => {
    let list = [...data.courseListData];
    const newOption = { ...object, dueIn: e.value };
    list[index] = newOption;
    updateData("courseListData", list);
  };

  const changeFrequency = (index, e, object) => {
    let list = [...data.courseListData];
    const newOption = { ...object, frequency: e.value };
    list[index] = newOption;
    updateData("courseListData", list);
  };

  const onSubmitClick = (e) => {
    if (data?.userData?.id) {
      let courselist = [];
      data?.courseListData.map((item, index) => {
        // Find the corresponding entry in optionalButCompletedCourses if available
        const optionalCourse = data?.optionalButCompletedCourses.find((course) => course.courseId === item.id);

        // Prioritize maxDueAt and completedAt from optionalCourse if available
        let maxDueAt = optionalCourse?.maxDueAt
          ? optionalCourse.maxDueAt // Assuming already in "YYYY-MM-DD"
          : item.maxDueAt
          ? moment(item.maxDueAt, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null;

        let completedAt = optionalCourse?.completedAt
          ? optionalCourse.completedAt // Assuming already in "YYYY-MM-DD"
          : item.completedAt
          ? moment(item.completedAt, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null;

        let courseDetails = {
          id: item.id,
          dueIn: item?.dueIn,
          maxDueAt: (item?.frequency === "ONE_OFF" && completedAt) || !maxDueAt ? null : maxDueAt,
          frequency: item?.frequency,
          completedAt: completedAt,
        };
        courselist.push(courseDetails);
      });

      let params = {
        userId: data.userData.id,
        course: courselist,
      };
      setIsLoading(true);
      assignCourseToStaff(params, (_, error) => {
        if (error === null) {
          setIsLoading(false);
          navigate(`${process.env.PUBLIC_URL}/staff-training`, {
            state: { id: data.userData.id },
          });
        } else {
          console.log(error);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="roletime-range" />
      {/* <Content> */}
      <Block>
        <DataTable className="card-stretch">
          <DataTableBody>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">
                  <strong>Training name</strong>
                </span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">
                  <strong>Date completed</strong>
                </span>
                <span>(Leave blank if not completed)</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">
                  <strong>Due date</strong>
                </span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">
                  <strong>DueIn</strong>
                </span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">
                  <strong>Frequency</strong>
                </span>
              </DataTableRow>
            </DataTableHead>
            {data.courseListData.length > 0
              ? data.courseListData.map((item, index) => {
                  if (item?.checked) {
                    const optionalCourse = data.optionalButCompletedCourses.find(
                      (course) => course.courseId === item.id
                    );

                    return (
                      <DataTableItem key={index}>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">{item.title}</span>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="position-relative">
                            <DatePicker
                              selected={
                                optionalCourse?.completedAt &&
                                moment(optionalCourse.completedAt, "YYYY-MM-DD").isValid()
                                  ? moment(optionalCourse.completedAt, "YYYY-MM-DD").toDate()
                                  : item?.completedAt && moment(item.completedAt, "DD-MM-YYYY").isValid()
                                  ? moment(item.completedAt, "DD-MM-YYYY").toDate()
                                  : null
                              }
                              className="form-control"
                              dateFormat={"dd-MM-yyyy"}
                              onChange={(date) => changeCompleteDate(index, date, item)}
                              // minDate={new Date()}
                            />
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="position-relative">
                            <DatePicker
                              selected={
                                (item?.frequency === "ONE_OFF" || optionalCourse?.frequency === "ONE_OFF") &&
                                (item?.completedAt || optionalCourse?.completedAt)
                                  ? null
                                  : optionalCourse?.maxDueAt && moment(optionalCourse.maxDueAt, "YYYY-MM-DD").isValid()
                                  ? moment(optionalCourse.maxDueAt, "YYYY-MM-DD").toDate()
                                  : item?.maxDueAt && moment(item.maxDueAt, "DD-MM-YYYY").isValid()
                                  ? moment(item.maxDueAt, "DD-MM-YYYY").toDate()
                                  : null
                              }
                              disabled={
                                (item?.frequency === "ONE_OFF" || optionalCourse?.frequency === "ONE_OFF") &&
                                (item?.completedAt || optionalCourse?.completedAt)
                              }
                              className="form-control"
                              dateFormat={"dd-MM-yyyy"}
                              onChange={(date) => changeDueDate(index, date, item)}
                              minDate={new Date()}
                            />
                          </div>
                        </DataTableRow>
                        <DataTableRow className="w-min-140px">
                          <div>
                            <RSelect
                              options={DueOptions}
                              onChange={(e) => changeDueIn(index, e, item)}
                              value={DueOptions.find((obj) => obj.value === item.dueIn)}
                            />
                          </div>
                        </DataTableRow>

                        <DataTableRow className="w-min-140px">
                          <div>
                            <RSelect
                              options={FrequencyOptions}
                              onChange={(e) => changeFrequency(index, e, item)}
                              value={FrequencyOptions.find((obj) => obj.value === item.frequency)}
                            />
                          </div>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  }
                })
              : null}
          </DataTableBody>
        </DataTable>
        <div>
          <ul className="d-flex justify-content-end mt-2">
            {/* <li className="mx-1">
                <Button
                  color="primary"
                  className="btn-text"
                  onClick={backClick}
                >
                  Back
                </Button>
              </li> */}
            <li className="">
              <Button
                color="primary"
                className="custom-solid-btn-sm"
                disabled={isLoading}
                type="submit"
                onClick={() => onSubmitClick()}
              >
                {isLoading ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
            </li>
          </ul>
        </div>
      </Block>
      {/* </Content> */}
    </React.Fragment>
  );
};

export default AssignTraining;
