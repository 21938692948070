import React, { useEffect } from "react";
import { useState } from "react";
import {
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  PreviewAltCard,
  Row,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchChatData, fetchDashboard } from "./DashboardData";
import { ActiveStaff, Certificates, Duetraining, FaceToFaceMeetings, Training } from "./Duetraining";
import { LocationDropDown } from "./Location/LocationDropDown";
import { useNavigate } from "react-router";
import moment from "moment";
import LoadingComponent from "../../components/spinner/LoadingSpinner";

const Dashboard = ({ ...props }) => {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const [location, setLocation] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function loadDashboard() {
    fetchDashboard(location?.id, (data, error) => {
      setData(data);
      loadDashboardGraphs();
      setIsInitScreen(false);
    });
  }

  function loadDashboardGraphs() {
    // const startDate = moment().subtract(2, "weeks").startOf("isoWeek").toDate();
    // const endDate = moment().subtract(1, "weeks").endOf("isoWeek").toDate();

    const startDate = moment().subtract(14, "days").toDate();
    const endDate = moment().subtract(1, "days").toDate();

    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");

    fetchChatData(location?.id, start, end, (data, error) => {
      if (error === null) {
        setChartData(data);
      }
    });
  }

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      {!isInitScreen && (
        <Content>
          <BlockHead size="sm">
            <div className="flex-column d-flex flex-md-row justify-content-start justify-content-md-between">
              <BlockHeadContent className="mb-3 mb-md-0">
                <BlockTitle tag="h3" page>
                  Welcome back, {(data?.userProfile?.firstName ?? "") + " " + (data?.userProfile?.lastName ?? "")}
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>Here is an Overview of your staff's training.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="w-250px">
                  <LocationDropDown
                    value={location}
                    className="w-250px"
                    onSelect={(e) => {
                      setLocation(e);
                    }}
                  />
                </div>
              </BlockHeadContent>
            </div>
          </BlockHead>
          <Row className="g-gs">
            <Col sm="12" lg="6">
              {/* <PreviewAltCard className={"bg-blue-dim"}> */}
              <PreviewAltCard>
                <Training
                  data={data}
                  chartData={chartData?.compliance ?? {}}
                  // chartData={saleRevenue}
                  onTraining={(ev) => {
                    navigate(`${process.env.PUBLIC_URL}/training-list`, {
                      state: {
                        location: location,
                      },
                    });
                  }}
                />
              </PreviewAltCard>
            </Col>

            <Col sm="12" lg="6">
              <PreviewAltCard>
                <Certificates
                  data={data}
                  chartData={chartData?.certificates ?? {}}
                  // chartData={saleRevenue}
                  onPressed={(ev) => {
                    navigate(`${process.env.PUBLIC_URL}/certificate-list`, {
                      state: {
                        total: data?.totalCertificateLastWeek ?? 0,
                        location: location,
                      },
                    });
                  }}
                />
              </PreviewAltCard>
              {/* <PreviewAltCard className={"bg-danger-dim"}> */}
              <PreviewAltCard>
                <Duetraining
                  data={data}
                  chartData={chartData?.overdue ?? {}}
                  // chartData={saleRevenue}
                  location={location}
                  onDidPressed={() => {
                    navigate(`${process.env.PUBLIC_URL}/staff`, {
                      state: { isOverdue: true, location: location },
                    });
                  }}
                />
              </PreviewAltCard>
            </Col>

            <Col sm="12" lg="6">
              <PreviewAltCard>
                <ActiveStaff
                  data={data}
                  onManageStaff={(ev) => {
                    navigate(`${process.env.PUBLIC_URL}/staff`, {
                      state: {
                        location: location,
                      },
                    });
                  }}
                />
              </PreviewAltCard>
            </Col>
            <Col sm="12" lg="6">
              <PreviewAltCard className="h-100">
                <FaceToFaceMeetings
                  data={data}
                  chartData={chartData?.ftf ?? {}}
                  // chartData={saleRevenue}
                />
              </PreviewAltCard>
            </Col>
          </Row>
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default Dashboard;
