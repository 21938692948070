import React, { useEffect } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import User from "./dropdown/user/User";
import MobileMenu from "../menu/MobileMenu";
import { menu, learnerMenu } from "../menu/MenuData";
import { isPendingCertificateAPI } from "../../pages/dashboard/Certificate/CertificateData";
import { useDispatch } from "react-redux";
import { setIsPending } from "../../redux/slices/certificateSlice";
import { Storage } from "../../pages/services/storage/storage";
import { UserAccountType } from "../../pages/utils";

const Header = ({ fixed, theme, visibility, toggleSidebar, mobileView, className, ...props }) => {
  const dispatch = useDispatch();

  const headerClass = classNames({
    "nk-header is-regular": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });
  let user = Storage.getLoggedInUser();

  useEffect(() => {
    loadIsPendingCertificateFlag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadIsPendingCertificateFlag() {
    let params = {
      isLearner: user?.accountType === UserAccountType.staff ? true : false,
    };
    isPendingCertificateAPI(params, (data, error) => {
      if (error === null) {
        dispatch(setIsPending(data?.totalPending));
      } else {
        console.error("Error fetching pending certificate flag", error);
      }
    });
  }

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger me-sm-2 d-lg-none">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={toggleSidebar} />
          </div>
          <div className="nk-header-brand fs-4 d-flex align-items-center ">
            <Logo />
            <p className="ms-2">SAUNDERS</p>
          </div>
          <div
            className={`nk-header-menu ms-auto ${mobileView ? "mobile-menu" : ""}  ${
              visibility ? "nk-header-active" : ""
            }`}
          >
            <div className="nk-header-mobile">
              <div className="nk-header-brand">
                <Logo />
              </div>
              <div className="nk-menu-trigger me-n2">
                <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="arrow-left" click={toggleSidebar} />
              </div>
            </div>
            {user?.accountType === UserAccountType.staff ? (
              <>
                {mobileView ? (
                  <MobileMenu data={learnerMenu} sidebarToggle={toggleSidebar} mobileView={mobileView} />
                ) : (
                  <Menu menu={learnerMenu} />
                )}
              </>
            ) : (
              <>
                {mobileView ? (
                  <MobileMenu data={menu} sidebarToggle={toggleSidebar} mobileView={mobileView} />
                ) : (
                  <Menu menu={menu} />
                )}
              </>
            )}
          </div>
          {visibility && <div className="nk-header-overlay" onClick={toggleSidebar}></div>}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="chats-dropdown">
                <></>
              </li>
              <li className="language-dropdown d-none d-sm-block me-n1">
                <></>
              </li>
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
