import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button, Col, Icon } from "../../../components/Component";
import { iconsType } from "../../Resources/ResourceData";
import { uploadCertificateFile } from "../../Stafflist/StaffData";
import { bytesToMegaBytes, showErrorToast } from "../../utils/Utils";
import { AppText } from "../../utils";

const UploadManualCertificate = ({ visible, trainingName, onClosed, onSuccess }) => {
  const [selectedFile, setSelectedFile] = useState();
  const { handleSubmit } = useForm();

  useEffect(() => {
    setSelectedFile(null);
    const fileInput = document.getElementById("files");
    if (fileInput) {
      fileInput.value = null; // Reset input field only if the element exists
    }
  }, [visible]);

  const handleFileSelect = (file) => {

    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;
      setSelectedFile(fileDetails);
      // for API
      uploadFile(fileDetails);
    }
  };

  const uploadFile = (file) => {

    uploadCertificateFile(file, "MANUAL_COURSE_CERTIFICATE", (data, error) => {
      if (error === null) {
        let object = {};
        object.id = data.id;
        object.isLoading = false;
        object.name = file.name;
        object.type = file.type;
        object.size = file.size;
        object.fileUrl = data.fileUrl;
        setSelectedFile(object);
      } else {
        setSelectedFile();
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  const onFinish = () => {
    if (selectedFile?.id) {
      onSuccess(selectedFile?.id);
    }
  };

  return (
    <Modal isOpen={visible} toggle={() => onClosed()} className="modal-md add-face-event-modal modal-dialog-scrollable">
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">Upload certificate</p>
      </ModalHeader>
      <ModalBody>
        <Form className="row gy-4" onSubmit={handleSubmit(onFinish)}>
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="event-title">
                {trainingName}
              </label>
            </div>
          </Col>
          <Col className="col-12  mb-3">
            <div className="form-group mb-0">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 fs-6 text-primary" />
                <span className="text-primary"> Attach document</span>
              </label>
              <input
                type="file"
                name="files"
                accept=".pdf,.png,.jpg,.jpeg,.svg,.webp,.heic,.doc,.docx"
                className="form-control"
                id={"files"}
                style={{ display: "none" }}
                onChange={(e) => {
                  let temp = Array.prototype.slice.call(e.target.files);
                  if (temp.length > 0) {
                    handleFileSelect(temp[0]);
                  }
                }}
              />
            </div>
            {selectedFile ? (
              <div className="nk-upload-item">
                <div className="nk-upload-icon">
                  {selectedFile?.mimeType ? (
                    <>{iconsType[selectedFile?.mimeType] ? iconsType[selectedFile?.mimeType] : iconsType["others"]}</>
                  ) : (
                    <>{iconsType[selectedFile?.type] ? iconsType[selectedFile?.type] : iconsType["others"]}</>
                  )}
                </div>
                <div className="nk-upload-info">
                  <div className="nk-upload-title">
                    <span
                      className="title"
                      style={{ cursor: "pointer" }}
                      onClick={() => openFileInNewTab(selectedFile)}
                    >
                      {selectedFile?.name ? selectedFile?.name : "File"}
                    </span>
                  </div>
                </div>
                <div className="nk-upload-action">
                  <a
                    href="#delete"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                  >
                    {selectedFile?.isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : (
                      <span onClick={() => setSelectedFile()}>
                        <Icon name="trash"></Icon>
                      </span>
                    )}
                  </a>
                </div>
              </div>
            ) : null}
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-2 gy-2">
              <li>
                <Button
                  color="primary"
                  type="submit"
                  className="custom-solid-btn-sm"
                  disabled={(selectedFile?.isLoading ?? true) === true}
                >
                  {"Finish"}
                </Button>
              </li>
              <li>
                <Button color="lighter text-gray" type="buttom" onClick={() => onClosed()}>
                  Cancel
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default UploadManualCertificate;
