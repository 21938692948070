import React, { useState } from "react";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewAltCard,
} from "../../components/Component";
import { Alert, Button, Col, Form, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { iconsType, saveResource, uploadResourceDoc } from "./ResourceData";
import { bytesToMegaBytes, showErrorToast } from "../utils/Utils";
import { AppText } from "../utils";
import { useLocation, useNavigate } from "react-router";

const NewAddResources = () => {
  const { handleSubmit } = useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorVal, setError] = useState();
  const [isRequiredError, setIsRequiredError] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    resourceLink: [
      {
        title: "",
        links: [{ url: "" }],
      },
    ],
    resourceFile: [{ id: 0, title: "", files: [] }],
    removeFileIds: [],
  });

  useEffect(() => {
    if (location?.state?.resourceData) {
      setFormData(location?.state?.resourceData);
    }
  }, [location?.state?.resourceData]);

  //   for link
  const handleAddLinkSection = () => {
    let resourceLinkList = formData?.resourceLink;
    resourceLinkList.push({ title: "", links: [{ url: "" }] });
    setFormData({ ...formData, resourceLink: resourceLinkList });
  };

  const handleRemoveLinkSection = (rootIndex) => {
    let resourceLinkList = formData?.resourceLink;
    resourceLinkList.splice(rootIndex, 1);
    setFormData({ ...formData, resourceLink: resourceLinkList });
  };

  const handleAddLink = (index, rootIndex) => {
    const updatedFormData = { ...formData }; // Create a copy of formData
    const updatedLinks = [...updatedFormData?.resourceLink[rootIndex]?.links]; // Create a copy of the links array
    updatedLinks.push({ url: "" }); // Add new link object to the links array
    updatedFormData.resourceLink[rootIndex].links = updatedLinks; // Update the links array within resourceLink
    setFormData(updatedFormData); // Set the updated formData
  };

  const handleRemoveLink = (index, rootIndex) => {
    const updatedFormData = { ...formData }; // Create a copy of formData
    const updatedLinks = [...updatedFormData?.resourceLink[rootIndex]?.links]; // Create a copy of the links array
    updatedLinks.splice(index, 1);
    updatedFormData.resourceLink[rootIndex].links = updatedLinks; // Update the links array within resourceLink
    setFormData(updatedFormData); // Set the updated formData
  };

  //   for file --------------------------------------
  const handleAddFileSection = () => {
    let resourceFileList = formData?.resourceFile;
    resourceFileList.push({
      id: undefined,
      title: "",
      files: [],
    });
    setFormData({ ...formData, resourceFile: resourceFileList });
  };

  const handleRemoveFileSection = (rootIndex) => {
    let resourceFileList = formData?.resourceFile;
    resourceFileList.splice(rootIndex, 1);
    setFormData({ ...formData, resourceFile: resourceFileList });
  };
  //   other function----------------------------------
  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedFormData = { ...formData }; // Create a copy of formData
      const updatedfiles = [...updatedFormData?.resourceFile[rootIndex]?.files]; // Create a copy of the files array
      updatedfiles.push({ id: updatedfiles.length, file: fileDetails });
      updatedFormData.resourceFile[rootIndex].files = updatedfiles; // Update the files array within resourceFile
      setFormData(updatedFormData);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1, rootIndex);
    }
  };

  const handleFileRemove = (ind, rootIndex) => {
    const updatedFormData = { ...formData }; // Create a copy of formData
    const updatedfiles = [...updatedFormData.resourceFile[rootIndex].files];

    updatedFormData.removeFileIds.push(updatedFormData.resourceFile[rootIndex].files[ind].file.id);

    updatedfiles.splice(ind, 1);
    updatedFormData.resourceFile[rootIndex].files = updatedfiles; // Update the files array within resourceFile

    setFormData(updatedFormData);
  };

  //   SUBMIT FORM--------------------------------------
  const onFormSubmit = () => {
    if ((formData?.title?.length ?? 0) === 0) {
      setIsRequiredError(true);
      return;
    }
    setIsRequiredError(false);

    let links = [];
    formData.resourceLink.map((item) => {
      let list = {
        title: item.title,
        list: item.links.flat(),
      };
      return links.push(list);
    });

    let groupFiles = [];
    for (let index = 0; index < formData.resourceFile.length; index++) {
      const item = formData.resourceFile[index];
      let object = {
        id: item?.id,
        title: item.title,
        fileIds: item.files
          .map((item) => (!item?.file?.isOldFile ? item?.file.id : null))
          .filter((item) => item !== null),
      };
      if ((object.id ?? 0) > 0 || object.fileIds.length > 0) {
        groupFiles.push(object);
      }
    }

    let params = {
      id: location?.state?.resourceData?.id ?? null,
      title: formData?.title,
      links: links,
      groupFiles: groupFiles,
      removeFileIds: formData?.removeFileIds ? formData?.removeFileIds : null,
    };

    setIsLoading(true);
    saveResource(params, (data, error) => {
      if (error === null) {
        setIsLoading(false);
        navigate(`${process.env.PUBLIC_URL}/resource-folders`);
      } else {
        setIsLoading(false);
        setError(error.message);
      }
    });
  };

  // API CALLING----------------------------------------
  const uploadFile = (file, index, rootIndex) => {
    uploadResourceDoc(file, (data, error) => {
      const updatedFormData = { ...formData }; // Create a copy of formData
      const updatedfiles = [...updatedFormData?.resourceFile[rootIndex].files];
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        updatedfiles[index] = file;
        setFormData(updatedFormData);
      } else {
        updatedfiles.splice(index, 1);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  return (
    <Content>
      <Block className="wide-md mx-auto">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Add Resources
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon mx-4 mt-3 mb-n3">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
        </BlockHead>
        <Block>
          <PreviewAltCard>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <label className="form-label">
                  <h5>Title</h5>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  value={formData.title}
                  placeholder="Enter Title"
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />
                {isRequiredError && (formData?.title?.length ?? 0) === 0 && (
                  <span className="invalid">{"This field is required"}</span>
                )}
              </div>
              {/* links-------------- section */}
              <BlockBetween>
                <BlockHeadContent>
                  <h5>Links</h5>
                </BlockHeadContent>
                <Button type="button" className="btn-text custom-solid-btn-sm" onClick={(e) => handleAddLinkSection()}>
                  Add
                </Button>
              </BlockBetween>
              <Block className="mt-2">
                {formData?.resourceLink.map((linkSection, index) => (
                  <Row className="my-1 align-items-center" key={index}>
                    <React.Fragment key={"section" + index}>
                      <Col className="col-1 text-center">
                        <p>#{index + 1}</p>
                      </Col>
                      <Col className="col-9 col-sm-10 mb-2">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="firstName"
                            value={formData.resourceLink[index].title}
                            placeholder="Enter title"
                            onChange={(e) => {
                              const updatedFormData = { ...formData };
                              updatedFormData.resourceLink[index].title = e.target.value; // Update the links array within resourceLink
                              setFormData(updatedFormData);
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12">
                        {linkSection.links.map((item, ind) => {
                          return (
                            <React.Fragment key={"files" + ind}>
                              <Row className={`${ind === 0 ? "" : "my-2"}`}>
                                <Col className="col-1"></Col>
                                <Col className="col-9 col-sm-10">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="firstName"
                                      value={formData.resourceLink[index].links[ind].url}
                                      placeholder="Enter link"
                                      onChange={(e) => {
                                        const updatedFormData = { ...formData };
                                        updatedFormData.resourceLink[index].links[ind].url = e.target.value; // Update the links array within resourceLink
                                        setFormData(updatedFormData);
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col className="col-1">
                                  {ind > 0 && (
                                    <Button
                                      className="me-2 bg-transparent border-0"
                                      type="button"
                                      onClick={() => handleRemoveLink(ind, index)}
                                    >
                                      <Icon className="text-gray" name="minus" />
                                    </Button>
                                  )}
                                  {ind === 0 && (
                                    <Button
                                      type="button"
                                      className="bg-transparent border-0"
                                      onClick={() => handleAddLink(ind, index)}
                                    >
                                      <Icon className="text-gray" name="plus" />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </React.Fragment>
                          );
                        })}
                        <Row>
                          <Col className="col-1"></Col>
                          <Col className="col-10">
                            {index > 0 && (
                              <Button
                                className="me-2 my-2 btn btn-dim btn-danger"
                                type="button"
                                onClick={() => handleRemoveLinkSection(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </React.Fragment>
                  </Row>
                ))}
              </Block>

              {/* files-----------section */}
              <BlockBetween className="mt-4">
                <BlockHeadContent>
                  <h5>Files</h5>
                </BlockHeadContent>
                <Button type="button" className="btn-text custom-solid-btn-sm" onClick={(e) => handleAddFileSection()}>
                  Add
                </Button>
              </BlockBetween>
              <Block className="mt-2">
                {formData?.resourceFile?.map((fileSection, fileSectionIndex) => (
                  <React.Fragment key={"section" + fileSectionIndex}>
                    <Row className="align-items-center">
                      <Col className="col-1 text-center">
                        <p>#{fileSectionIndex + 1}</p>
                      </Col>
                      <Col className="col-9 col-sm-10 my-1">
                        <div className="form-group mb-1">
                          <input
                            className="form-control"
                            type="text"
                            name="firstName"
                            value={formData.resourceFile[fileSectionIndex].title}
                            placeholder="Enter title"
                            onChange={(e) => {
                              const updatedFormData = { ...formData };
                              updatedFormData.resourceFile[fileSectionIndex].title = e.target.value; // Update the links array within resourceLink
                              setFormData(updatedFormData);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Col className="col-9 col-sm-10 mt-n2 mb-3">
                      <Row>
                        <Col className="col-1"></Col>
                        <Col className="col-10 ">
                          <div className="form-group mb-0" style={{ marginTop: 14 }}>
                            <label htmlFor={"files" + fileSectionIndex}>
                              <Icon name="clip" className="mb-1 text-primary" />
                              <span className="text-primary"> Attach resource</span>
                            </label>
                            <input
                              type="file"
                              name="files"
                              accept=".pdf,.doc,.docx"
                              className="form-control"
                              id={"files" + fileSectionIndex}
                              style={{ display: "none" }}
                              onChange={(e) => {
                                let temp = Array.prototype.slice.call(e.target.files);
                                if (temp.length > 0) {
                                  handleFileSelect(temp[0], fileSectionIndex);
                                }
                              }}
                            />
                          </div>

                          {fileSection?.files.map((file, ind) => {
                            return (
                              <React.Fragment key={`file-${ind}`}>
                                <div className="nk-upload-item">
                                  <div className="nk-upload-icon">
                                    {file.file.isOldFile ? (
                                      <>
                                        {iconsType[file.file.mimeType]
                                          ? iconsType[file.file.mimeType]
                                          : iconsType["others"]}
                                      </>
                                    ) : (
                                      <>{iconsType[file.file.type] ? iconsType[file.file.type] : iconsType["others"]}</>
                                    )}
                                  </div>
                                  <div className="nk-upload-info">
                                    <div className="nk-upload-title">
                                      <span className="title">{file.file.name}</span>
                                    </div>
                                  </div>
                                  <div className="nk-upload-action">
                                    <a
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                                    >
                                      {file.file.isLoading ? (
                                        <Spinner size="sm" color="dark" />
                                      ) : (
                                        <span onClick={() => handleFileRemove(ind, fileSectionIndex)}>
                                          <Icon name="trash"></Icon>
                                        </span>
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-1"></Col>
                        <Col className="col-10">
                          {fileSectionIndex > 0 && (
                            <Button
                              className="me-2 my-2 btn btn-dim btn-danger"
                              type="button"
                              onClick={() => handleRemoveFileSection(fileSectionIndex)}
                            >
                              Remove
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </React.Fragment>
                ))}
              </Block>

              <Button className="me-2 my-2 btn custom-solid-btn-sm" type="submit">
                {isLoading ? <Spinner size="sm" color="light" /> : "Save"}
              </Button>
            </Form>
          </PreviewAltCard>
        </Block>
      </Block>
    </Content>
  );
};

export default NewAddResources;
