import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  PreviewAltCard,
} from "../../../components/Component";
import EvalutionFormRadioOption from "./EvalutionFormRadioOption";
import BackButton from "../../../components/button/BackButton";
import { fetchEvaluationAnswer, fetchEvaluationFormDetail, fetchEvaluationQuestionList } from "./DueTrainingData";
import ActionButton from "../../../components/button/ActionButton";
import { showErrorToast } from "../../utils";
import LoadingComponent from "../../../components/spinner/LoadingSpinner";
import { useLocation, useNavigate } from "react-router";

const EvalutionForm = () => {
  const [isEditing, setIsEditing] = useState(true);
  const [queList, setQueList] = useState([]);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInitScreen, setIsInitScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  window.onpopstate = (e) => {
    if (location?.state?.isEvaluationFormMoved) {
      navigate(`${process.env.PUBLIC_URL}/`);
    }
  };

  useEffect(() => {
    loadEvaluationQueList();
  }, []);

  useEffect(() => {
    const areAllQuestionsAnswered = queList.every((question) => {
      const answer = formData[question.id];
      return answer !== undefined && String(answer).trim() !== "";
    });
    setIsFormValid(areAllQuestionsAnswered);
  }, [formData, queList]);

  useEffect(() => {
    if (location?.state?.isEvaluationFormView === true) {
      evaluationFormDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.isEvaluationFormView]);

  // ********************* API ******************* //

  function loadEvaluationQueList() {
    setIsInitScreen(true);
    fetchEvaluationQuestionList((data, error) => {
      if (error === null) {
        setQueList(data?.list);
      } else {
        showErrorToast(error.message);
      }
      setIsInitScreen(false);
    });
  }

  const handleSubmit = () => {
    const queAns = Object.entries(formData).map(([quesId, answer]) => ({
      quesId: parseInt(quesId),
      answer: answer.toString(),
    }));
    let params = {
      courseCompletedId: location?.state?.courseCompletedId,
      quesAns: queAns,
    };
    setLoading(true);
    fetchEvaluationAnswer(params, (data, error) => {
      if (error === null) {
        navigate(`${process.env.PUBLIC_URL}/course-assesment-test`, {
          state: {
            questionData: location?.state?.questionData,
            courseDetails: location?.state?.courseDetails,
          },
        });
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const evaluationFormDetail = () => {
    let params = {
      courseCompletedId: location?.state?.courseCompletedId,
    };
    fetchEvaluationFormDetail(params, (data, error) => {
      if (error === null) {
        const updatedFormData = { ...formData }; // Create a copy of current formData
        data?.data.forEach((item) => {
          const answer = item.answer; // Get the answer from the API response
          const parsedAnswer = /^[0-9]$/.test(answer) ? parseInt(answer) : answer; // Parse the answer if it's a single digit
          updatedFormData[item?.question?.quesId] = parsedAnswer;
        });
        setFormData(updatedFormData);
      }
    });
  };

  // ********************** Functions ********************** //

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to toggle editing state
  // eslint-disable-next-line no-unused-vars
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  // const criteriaList = [
  //   { id: 1, que: "Training was relevant to my needs." },
  //   { id: 2, que: "Length of training was sufficient." },
  //   { id: 3, que: "Content was well organised." },
  //   { id: 4, que: "Video content was appropriate." },
  //   { id: 5, que: "Questions asked were appropriate to the content." },
  //   { id: 6, que: "Training met my expectations." },
  //   { id: 7, que: "I would recommend this training to colleagues." },
  // ];

  return (
    <React.Fragment>
      <Head title="Evaluation form" />
      {queList?.length > 0 && (
        <Content>
          <Block className={"justify-center"}>
            <BlockHead size="lg" wide="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h3" page>
                    Training evaluation form
                  </BlockTitle>
                </BlockHeadContent>
                {location?.state?.isEvaluationFormView === true && (
                  <BlockHeadContent>
                    <BackButton />
                  </BlockHeadContent>
                )}
              </BlockBetween>
              <PreviewAltCard className="mt-2">
                <EvalutionFormRadioOption
                  criteriaList={queList}
                  formData={formData}
                  handleInputChange={handleInputChange}
                  disabled={location?.state?.isEvaluationFormView === true}
                />
                <div className="pt-4">
                  {queList
                    .filter((item) => item.quesType === "GENERAL")
                    .map((item) => (
                      <React.Fragment key={item.id}>
                        <div>{item.quesDesc}</div>
                        <textarea
                          className="form-control mb-4"
                          id={item.id}
                          name={item.id}
                          maxLength={200}
                          value={formData[item.id] || ""} // Set textarea value based on formData
                          disabled={!isEditing || location?.state?.isEvaluationFormView === true}
                          onChange={handleInputChange}
                        ></textarea>
                      </React.Fragment>
                    ))}
                </div>
                {location?.state?.isEvaluationFormView !== true && (
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap pt-3 justify-content-end">
                      <li>
                        <ActionButton
                          title="Take Assessment"
                          onClick={handleSubmit}
                          disabled={!isFormValid || location?.state?.isEvaluationFormView === true}
                          loading={loading}
                        />
                      </li>
                    </ul>
                  </Col>
                )}
              </PreviewAltCard>
            </BlockHead>
          </Block>
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};
export default EvalutionForm;
