/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Col, Icon, PreviewAltCard } from "../../components/Component";
import { Form, Spinner } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { ticketSaveApi, uploadTicketFile } from "./MessageData";
import { iconsType } from "../Resources/ResourceData";
import ActionButton from "../../components/button/ActionButton";
import { AppText, showErrorToast } from "../utils";
import { bytesToMegaBytes } from "../utils/Utils";
import { useLocation, useNavigate } from "react-router";

const AddTicket = () => {
  const editorRef = useRef(null);
  const [ticketData, setTicketData] = useState({
    title: "",
    description: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const editItem = location?.state?.item;

  useEffect(() => {
    if (editItem) {
      setTicketData({
        title: editItem.title,
        description: editItem.desc,
      });

      const filesFromEditItem = editItem?.files?.map((file) => ({
        id: file.id,
        name: file.name,
        fileUrl: file.fileUrl,
        mimeType: file.mimeType,
        isOldFile: true,
        // Add other properties as needed
      }));
      setUploadedFiles(filesFromEditItem);
    }
  }, [editItem]);

  const uploadFile = (file, index) => {
    uploadTicketFile(file, (data, error) => {
      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        file.fileUrl = data.fileUrl;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const handleFileSelect = (file) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      updatedfiles.push(fileDetails);
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };
  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles]; // Create a copy of formData

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1); // Update the files array within resourceFile

    setUploadedFiles(updatedfiles);
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  const onFormSubmit = () => {
    if ((ticketData?.title ?? "").trim().length === 0) {
      showErrorToast("Please enter at least title");
      return;
    }

    let fileIds = uploadedFiles.filter((file) => !file.isOldFile).map((file) => file.id);

    let params = {
      id: editItem?.id ?? undefined,
      subject: ticketData?.title?.trim() ?? "",
      desc: ticketData?.description ?? "",
      // statusReason: "string",
      // status: "OPEN",
      fileIds: fileIds ?? [],
      removeFileIds: removeFileIds ?? [],
    };
    setLoading(true);
    ticketSaveApi(params, (data, error) => {
      if (error === null) {
        navigate(-1);
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="add-ticket" />
      <Content>
        <Block className={"justify-center"}>
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {editItem ? "Edit Ticket" : "Create Ticket"}
              </BlockTitle>
              <PreviewAltCard>
                <Form className="row gy-4">
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        value={ticketData?.title ?? ""}
                        placeholder="Type your title here"
                        onChange={(e) =>
                          setTicketData((prevData) => ({
                            ...prevData,
                            title: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Description </label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={ticketData?.description ?? ""}
                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                        onEditorChange={(value) => {
                          setTicketData((prev) => ({
                            ...prev,
                            html: value,
                            description: value.toString("html"),
                          }));
                        }}
                        init={{
                          menubar: false,
                          statusbar: false,
                          height: 450,
                          automatic_uploads: false,
                          file_picker_callback: function (callback, value, meta) {
                            // Provide file and text for the link dialog
                            if (meta.filetype === "file") {
                              callback("mypage.html", { text: "My text" });
                            }

                            // Provide image and alt text for the image dialog
                            if (meta.filetype === "image") {
                              callback("myimage.jpg", { alt: "My alt text" });
                            }

                            // Provide alternative source and posted for the media dialog
                            if (meta.filetype === "media") {
                              callback("movie.mp4", {
                                source2: "alt.ogg",
                                poster: "image.jpg",
                              });
                            }
                          },
                          // fontsize_formats:
                          //   "8px 9px 10px 11px 12px 14px 15px 16px 17px 18px 19px 20px 24px 30px 36px 48px 60px 72px 96px",
                          paste_data_images: true,
                          // images_upload_url: "postAcceptor.php",

                          /* we override default upload handler to simulate successful upload*/
                          images_upload_handler: function (blobInfo, success, failure) {
                            setTimeout(function () {
                              success();
                            }, 2000);
                          },
                          content_style: "body { font-size: 11pt; color: #3c4d62; }",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code",
                          ],
                          toolbar1: "bold italic underline | code | numlist bullist | ",
                          lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 35px 36px",
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="col-12  mb-3">
                    <div className="form-group mb-0">
                      <label htmlFor="files">
                        <Icon name="clip" className="mb-1 fs-6 text-primary" />
                        <span className="text-primary"> Attach document</span>
                      </label>
                      <input
                        type="file"
                        name="files"
                        accept=".pdf,.doc,.docx,.xlsx,.xls,.png,.jpg,.jpeg,.svg,.webp"
                        className="form-control"
                        id={"files"}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          let temp = Array.prototype.slice.call(e.target.files);
                          if (temp.length > 0) {
                            handleFileSelect(temp[0]);
                          }
                        }}
                      />
                    </div>
                    {uploadedFiles?.length > 0 &&
                      uploadedFiles?.map((file, ind) => {
                        return (
                          <React.Fragment key={`file-${ind}`}>
                            <div className="nk-upload-item">
                              <div className="nk-upload-icon">
                                {file?.mimeType ? (
                                  <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                                ) : (
                                  <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                                )}
                              </div>
                              <div className="nk-upload-info">
                                <div className="nk-upload-title">
                                  <span
                                    className="title"
                                    style={{ cursor: "pointer" }} // Add cursor pointer style
                                    onClick={() => openFileInNewTab(file)} // Handle click event
                                  >
                                    {file?.name ? file?.name : "Ticket"}
                                  </span>
                                </div>
                              </div>
                              <div className="nk-upload-action">
                                <a
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                  className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                                >
                                  {file?.isLoading ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <span onClick={() => handleFileRemove(ind)}>
                                      <Icon name="trash"></Icon>
                                    </span>
                                  )}
                                </a>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <ActionButton title="Send" loading={loading} onClick={() => onFormSubmit()} />
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            navigate(-1);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </PreviewAltCard>
            </BlockHeadContent>
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddTicket;
