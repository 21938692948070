import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button, Col, RSelect } from "../../components/Component";
import { LocationDropDown } from "../dashboard/Location/LocationDropDown";
import { saveFaceToFaceData } from "./FaceToFaceData";
import { CourseDropDown } from "./CourseDropDown";
import GlobalRadioButtons from "../../components/radiobutton/GlobalRadioButton";
import { Storage } from "../services/storage/storage";
import { FaceToFaceDuration, UserAccountType } from "../utils/Utils";

const AddFaceEvent = ({ visible, dataInfo, isAddTraining = false, onClosed }) => {
  const user = Storage.getLoggedInUser();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [location, setLocation] = useState();
  const [course, setCourse] = useState();
  const [title, setTitle] = useState("");
  const [seats, setSeats] = useState();
  const [isZoom, setIsZoom] = useState(false);
  const [description, setDescription] = useState();
  const [duration, setDuration] = useState();
  const { errors, register } = useForm();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGlobal, setIsGlobal] = useState(true);

  useEffect(() => {
    if (!visible) {
      setTitle();
      setCourse();
      setStartDate(new Date());
      setDescription();
      setDuration();
      setSeats();
      setLocation();
      setIsGlobal(true); // Reset the state
    }
  }, [visible]);

  useEffect(() => {
    if (isAddTraining) {
      dataInfo && setStartDate(dataInfo);
      setStartTime(
        moment()
          .add(15 - (moment().minute() % 15), "m")
          .toDate()
      );
    } else if (dataInfo !== undefined) {
      let eventdate = dataInfo.dateTime;
      let location = {
        id: dataInfo?.location?.id,
        value: dataInfo?.location?.name,
        label: dataInfo?.location?.name,
      };
      let course = {
        id: dataInfo?.course?.id,
        value: dataInfo?.course?.name,
        label: dataInfo?.course?.name,
      };

      if (dataInfo?.course?.id) {
        setCourse(course);
      } else {
        let course = {
          id: undefined,
          value: "Others",
          label: "Others",
        };
        setCourse(course);
        setTitle(dataInfo?.title);
      }
      const dateObject = new Date(eventdate);
      setStartDate(dateObject);
      setStartTime(dateObject);
      setLocation(location);
      setDescription(dataInfo?.description);
      setSeats("" + dataInfo?.totalSeats ?? null);
      if (dataInfo?.duration) {
        let duration = FaceToFaceDuration.find((item) => item.value === dataInfo?.duration);
        setDuration(duration);
      }
      setIsZoom(dataInfo?.isZoom ?? false);
      setIsGlobal(dataInfo?.isGlobal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfo]);

  const handleFormSubmit = (formData) => {
    if (startDate !== undefined && startTime !== undefined) {
      const datetime = moment(
        moment(startDate).format("YYYY-MM-DD") + " " + moment(startTime).format("HH:mm:ss"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      let params = {
        id: dataInfo?.id,
        title: course?.value === "Others" ? title : course?.value,
        courseId: course?.id,
        dateTime: datetime,
        locationId: isZoom ? null : location.id,
        description: description,
        totalSeats: parseInt(seats),
        status: "NEW",
        duration: duration?.value ?? FaceToFaceDuration[1].value,
        isZoomMeeting: isZoom,
        isGlobal: user?.accountType !== UserAccountType.staff ? isGlobal : false, // Set isGlobal based on the state
      };
      addFaceToFaceTraining(params);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  function addFaceToFaceTraining(params) {
    setIsLoading(true);
    saveFaceToFaceData(params, (data, error) => {
      if (error !== null) {
        setIsLoading(false);
        onClosed(data.data);
      } else {
        console.log(error);
      }
    });
  }

  return (
    <Modal isOpen={visible} toggle={() => onClosed()} className="modal-lg add-face-event-modal modal-dialog-scrollable">
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">Face-to-Face</p>
      </ModalHeader>
      <ModalBody>
        <Form className="row gy-4">
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="event-title">
                Select Course
              </label>
              <div className="form-control-wrap">
                <CourseDropDown
                  value={course}
                  onSelect={(e) => {
                    setCourse(e);
                  }}
                />
                {/* {errors.title && (
                  <p className="invalid">This field is required</p>
                )} */}
              </div>
            </div>
          </Col>
          <Col md="6">
            {course?.value === "Others" && (
              <div className="form-group">
                <label className="form-label" htmlFor="event-title">
                  Title
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="event-title"
                    name="title"
                    value={title ?? ""}
                    className="form-control"
                    maxLength={100}
                    {...register("title", { required: true })}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && <p className="invalid">This field is required</p>}
                </div>
              </div>
            )}
          </Col>
          <Col md="4">
            <div className="form-group w-100">
              <label className="form-label"> Date </label>
              <div className="form-control-wrap">
                <div className="position-relative">
                  {/* <em className="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                  <DatePicker
                    toggleCalendarOnIconClick
                    selected={startDate ?? new Date()}
                    minDate={new Date()}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    dateFormat={"dd-MM-yyyy"}
                    className="form-control date-picker"
                  />
                </div>
                {isError && startDate === undefined && (
                  // <ErrorText />
                  <>This field is requires</>
                )}
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="form-group w-100">
              <label className="form-label">Time</label>
              <div className="form-control-wrap ">
                <div className="position-relative">
                  {/* <em className="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => {
                      setStartTime(date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm a"
                    timeFormat="HH:mm a"
                    className="form-control date-picker"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="form-group w-100">
              <label className="form-label">Duration</label>
              <div className="form-control-wrap">
                <RSelect
                  options={FaceToFaceDuration}
                  value={duration ?? FaceToFaceDuration[1]}
                  placeholder="Duration"
                  onChange={(e) => setDuration(e)}
                />
              </div>
            </div>
          </Col>
          {!isZoom && (
            <Col size="6">
              <div className="form-group">
                <label className="form-label">Location</label>
                <div className="form-control-wrap">
                  <LocationDropDown
                    value={location}
                    className="w-100"
                    isMulti={false}
                    isShowAll={false}
                    onSelect={(e) => {
                      setLocation(e);
                    }}
                    disable={dataInfo && user?.accountType === UserAccountType.manager}
                  />
                </div>
              </div>
            </Col>
          )}

          <Col sm="6">
            <div className="form-group">
              <label className="form-label">Seats</label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  id="event-title"
                  name="seats"
                  className="form-control"
                  value={seats ?? ""}
                  {...register("seats", { required: true })}
                  onChange={(e) => setSeats(e.target.value)}
                />
                {/* {errors.title && (
                  <p className="invalid">This field is required</p>
                )} */}
              </div>
            </div>
          </Col>
          <Col size="6">
            <div className={`form-group ${isZoom ? "mt-4 pt-2" : ""}`}>
              <span className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="isZoom"
                    id={`isZoom`}
                    checked={isZoom}
                    onChange={(e) => setIsZoom(e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor={`isZoom`}>
                    Zoom meeting
                  </label>
                </div>
              </span>
            </div>
          </Col>
          {(user?.accountType === UserAccountType.admin || user?.accountType === UserAccountType.manager) && (
            <Col size="6">
              <div className="form-group">
                <div className="d-flex">
                  <label className="form-label">Is Global?</label>
                  <GlobalRadioButtons
                    name="isGlobal"
                    defaultValue={isGlobal}
                    onChange={(e) => setIsGlobal(e.target.value === "yes")} // Update the state based on the radio button selection
                  />
                </div>
              </div>
            </Col>
          )}

          <Col size="12">
            <div className="form-group">
              <label className="form-label" htmlFor="event-description">
                Event Description
              </label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  id="event-description"
                  name="description"
                  value={description ?? ""}
                  {...register("description", { required: true })}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                {errors?.description && <p className="invalid">This field is required</p>}
              </div>
            </div>
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-2 gy-2">
              <li>
                <Button
                  color="primary"
                  type="button"
                  className="custom-solid-btn-sm"
                  disabled={
                    !(course?.value === "Others" ? title : course?.value) ||
                    !seats ||
                    !description ||
                    (!isZoom && !location?.value) // iszoom or location
                  }
                  onClick={() => handleFormSubmit()}
                >
                  {isLoading ? <Spinner size="sm" color="dark" /> : "Save"}
                </Button>
              </li>
              <li>
                <Button color="lighter text-gray" type="buttom" disabled={isLoading} onClick={() => onClosed()}>
                  Cancel
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default AddFaceEvent;
