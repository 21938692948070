import React from "react";
import { Col, Row } from "../Component";
import { RadioOptionTypes } from "../../pages/utils/Utils";

export const RadioButton = ({ optionType, size, onChange, activeIndex, name }) => {
  const handleRadioChange = (index) => {
    if (onChange) {
      onChange(index);
    }
  };

  const getTypeOptions = (optionType) => {
    switch (optionType) {
      case RadioOptionTypes.role:
        return ["Mandatory", "Optional"];
      default:
        return [];
    }
  };
  const options = getTypeOptions(optionType);

  return (
    <Row>
      {options.map((item, index) => {
        return (
          <Col key={index} size={size}>
            <label className="radio-label align-items-center d-flex align-items-center">
              <input
                className="me-1"
                type="radio"
                name={name}
                onChange={() => handleRadioChange(index)}
                checked={index === activeIndex}
              />
              {/* <span className="radio-indicator"></span> */}
              <span className="radio-label-text">
                {/* <Icon className="me-2" name={item.icon} /> */}
                {item}
              </span>
            </label>
          </Col>
        );
      })}
    </Row>
  );
};
