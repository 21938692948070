import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { fetchAssesmentResultDetail } from "./DueTrainingData";
import ResultedQuestion from "./ResultedQuestion";
import { sendUnlockCourseRequest } from "../Training/TrainingData";
import { Spinner } from "reactstrap";
import { QuestionType, showErrorToast, showSuccessToast } from "../../utils";

const Result = () => {
  // eslint-disable-next-line no-unused-vars
  const [percentage, setPercentage] = useState(50);
  const [resultdata, setResultData] = useState();
  const [isAlreadySendRequest, seIsAlreadySendRequest] = useState(false);
  const [isSendingUnlockRequest, setIsSendingUnlockRequest] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    loadResultDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  window.onpopstate = (e) => {
    navigate(`${process.env.PUBLIC_URL}/`);
  };

  const retakeTest = () => {
    if (resultdata?.data?.isRetakeBlock) {
      navigate(`${process.env.PUBLIC_URL}/`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/course-assesment-test`, {
        state: {
          questionData: location.state?.questionData,
          courseDetails: location.state?.courseDetails,
        },
      });
    }
  };
  const loadResultDetails = () => {
    let params = {
      courseId: location.state?.courseId,
      userId: location.state?.userData?.id,
    };
    fetchAssesmentResultDetail(params, (data, error) => {
      if (error === null) {
        setResultData(data);
      }
    });
  };

  const sendRequestToManage = () => {
    if (location.state?.courseDetails?.id) {
      setIsSendingUnlockRequest(true);
      sendUnlockCourseRequest(location.state?.courseDetails?.id, (data, error) => {
        if (error === null) {
          seIsAlreadySendRequest(true);
          showSuccessToast("Your request has been sent to your manager");
        } else {
          showErrorToast(error.message);
        }
        setIsSendingUnlockRequest(false);
      });
    }
  };

  return (
    <Content>
      <div className="wide-sm mx-auto">
        <p className="fs-1 fw-500 text-dark mb-2">Result</p>
        <div className={`${location?.state?.onlyResult === true ? "d-none" : ""}`}>
          <p className="fs-6 fw-500 mb-3">You didn't pass this time , here's your result:</p>
          <div className="d-flex align-items-center">
            <CircularProgressbar
              className="w-25 me-4 w-250"
              value={resultdata?.data?.percentage ?? 0}
              text={`${resultdata?.data?.percentage ?? 0}%`}
              styles={buildStyles({
                textSize: "16px",
                pathTransitionDuration: 0.5,
                textColor: `${
                  percentage >= 80 ? "#28a745" : percentage < 80 && percentage >= 50 ? "#ffc107" : "#dc3545"
                }`,
                trailColor: "#d6d6d6",
                pathColor: `${
                  percentage >= 80 ? "#28a745" : percentage < 80 && percentage >= 50 ? "#ffc107" : "#dc3545"
                }`,
              })}
            />
            <p className="text-dark">
              {resultdata?.data?.percentage ?? 0}% of your answer were correct (80% correct answer needed).
            </p>
          </div>
          <p className="fs-1 fw-500  mb-2">Your answers</p>
        </div>

        {resultdata?.questions?.length > 0 ? (
          <>
            <p className="fs-6 fw-500 mb-3">
              You have already successfully completed this end of course test and it is not due for renewal at this
              time, the answers can be reviewed below.
            </p>
          </>
        ) : (
          <>
            <p className="fs-6 fw-500 mb-3">
              The end of course test is not visible as this training is in date and not due renewal. If you would like
              to redo the end of course test prior to the renewal date please contact your Manager and ask for the
              course to be reset.
            </p>
          </>
        )}
        {resultdata?.questions?.map((question, index) => (
          <React.Fragment key={`question-${index}`}>
            <ResultedQuestion
              question={question?.description}
              isanswer={question?.answerLog?.result === "INCORRECT" ? false : true}
              answer={
                question?.type === QuestionType.msq
                  ? question?.answerLog?.answer.map((answerIndex) => question.options[answerIndex])
                  : [question.options[question?.answerLog?.answer]]
              }
              // answer={[question.options[question?.answerLog?.answer]]}
            />
          </React.Fragment>
        ))}

        {resultdata?.data?.isRetakeBlock === true && (
          <label className="text-danger">
            <br />
            Unfortunately you have reached the maximum test attempts and have been unable to pass the end of course
            test. Therefore, your training is not complete.
            <br />
            <br />
            Please select 'Send a reset request' to your manager for a training reset.
            <br />
            <br />
            The full training will need to be retaken, and you will have 3 further attempts to pass the
            end of course test.
            <br />
            <br />
          </label>
        )}

        <button
          className={`btn btn-primary btn-text custom-solid-btn-sm ${
            location?.state?.onlyResult === true ? "d-none" : ""
          } `}
          onClick={() => retakeTest()}
        >
          {resultdata?.data?.isRetakeBlock === false ? "Retake Assesment" : "Go to dashboard"}
        </button>
        {resultdata?.data?.isRetakeBlock === true && isAlreadySendRequest === false && (
          <button
            className={`ms-2 btn btn-primary btn-text custom-solid-btn-sm ${
              location?.state?.onlyResult === true ? "d-none" : ""
            } `}
            onClick={() => sendRequestToManage()}
          >
            {isSendingUnlockRequest ? <Spinner size="sm" color="light" /> : "Send a reset request"}
          </button>
        )}
      </div>
    </Content>
  );
};

export default Result;
