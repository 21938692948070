/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect } from "../../components/Component";
import ReactDatePicker from "react-datepicker";
import { fetchCPDSave, uploadCertificateFile } from "./StaffData";
import moment from "moment";
import { AppText, showErrorToast } from "../utils";
import { iconsType } from "../Resources/ResourceData";
import { bytesToMegaBytes, FDUDuration, TrainingType } from "../utils/Utils";

const AddCpd = ({ visible, staffId, onClosed, onSuccess }) => {
  const [formData, setFormData] = useState({
    trainingName: "",
    completionDate: null,
    description: "",
    duration: "",
    trainingType: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (visible) {
      setFormData({
        trainingName: "",
        completionDate: null,
        description: "",
        duration: "",
        trainingType: "",
      });
      setUploadedFiles([]);
    }
  }, [visible]);

  const handleFormSubmit = () => {
    let fileId = null;
    if (uploadedFiles.length > 0 && !uploadedFiles[0].isOldFile) {
      fileId = uploadedFiles[0].id;
    }

    let params = {
      userId: staffId,
      name: formData?.trainingName,
      cpdType: formData?.trainingType,
      facilitator: formData?.description,
      completedDate: moment(formData?.completionDate).format("YYYY-MM-DD"),
      trainingLength: formData?.duration?.value ?? undefined,
      fileId: fileId,
    };
    setIsLoading(true);
    fetchCPDSave(params, (data, error) => {
      if (error === null) {
        onClosed();
        onSuccess();
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  const uploadFile = (file, index) => {
    uploadCertificateFile(file, "CPD_CERTIFICATE", (data, error) => {
      const updatedfiles = [...uploadedFiles];
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        file.fileUrl = data.fileUrl;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array

      if (updatedfiles.length > 0) {
        updatedfiles[0] = fileDetails; // Replace the existing file with the new one
      } else {
        updatedfiles.push(fileDetails); // If no file is uploaded, simply push the new file
      }
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles];

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1);

    setUploadedFiles(updatedfiles);
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  return (
    <Modal isOpen={visible} toggle={() => onClosed()} className="modal-md add-face-event-modal modal-dialog-scrollable">
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">Add CPD Training</p>
      </ModalHeader>
      <ModalBody>
        <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="event-title">
                Training Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="event-title"
                  name="TrainingName"
                  maxLength={100}
                  {...register("trainingName", { required: true })} // Updated API usage
                  value={formData?.trainingName}
                  onChange={(e) => setFormData({ ...formData, trainingName: e.target.value })}
                  className={`form-control ${errors?.trainingName}`}
                />
                {/* {errors.TrainingName && (
                  <p className="invalid">This field is required</p>
                )} */}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group w-100">
              <label className="form-label">Completion Date</label>
              <div className="form-control-wrap">
                <div className="position-relative">
                  <ReactDatePicker
                    selected={formData.completionDate}
                    onChange={(date) => setFormData({ ...formData, completionDate: date })}
                    dateFormat="dd-MM-yyyy"
                    className={`form-control date-picker`}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group w-100">
              <label className="form-label">Duration</label>
              <div className="form-control-wrap">
                <RSelect
                  className="custom-height"
                  options={FDUDuration}
                  placeholder="Duration"
                  onChange={(e) => setFormData({ ...formData, duration: e })}
                />
              </div>
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="form-label">Training Type</label>
              <div className="form-control-wrap">
                <RSelect
                  options={TrainingType}
                  placeholder="Select Training Type"
                  onChange={(e) => setFormData({ ...formData, trainingType: e.value })}
                />
              </div>
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="form-label" htmlFor="event-title">
                Facilitator Of Training
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control"
                  id="event-description"
                  name="description"
                  maxLength={100}
                  {...register("description", { required: true })} // Updated API usage
                  value={formData?.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                ></input>
                {/* {errors.description && (
                  <p className="invalid">This field is required</p>
                )} */}
              </div>
            </div>
          </Col>
          <Col className="col-12  mb-3">
            <div className="form-group mb-0">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 fs-6 text-primary" />
                <span className="text-primary"> Attach document</span>
              </label>
              <input
                type="file"
                name="files"
                accept=".pdf,.png,.jpg,.jpeg,.svg,.webp,.heic,.doc,.docx"
                className="form-control"
                id={"files"}
                style={{ display: "none" }}
                onChange={(e) => {
                  let temp = Array.prototype.slice.call(e.target.files);
                  if (temp.length > 0) {
                    handleFileSelect(temp[0]);
                  }
                }}
              />
            </div>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file, ind) => {
                return (
                  <React.Fragment key={`file-${ind}`}>
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {file?.mimeType ? (
                          <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                        ) : (
                          <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                        )}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span className="title" style={{ cursor: "pointer" }} onClick={() => openFileInNewTab(file)}>
                            {file?.name ? file?.name : "Ticket"}
                          </span>
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {file?.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span onClick={() => handleFileRemove(ind)}>
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-2 gy-2">
              <li>
                <Button color="primary" type="submit" className="custom-solid-btn-sm" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" color="dark" /> : "Save"}
                </Button>
              </li>
              <li>
                <Button color="lighter text-gray" type="buttom" disabled={isLoading} onClick={() => onClosed()}>
                  Cancel
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default AddCpd;
