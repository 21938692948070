import { UserAccountType } from "../../pages/utils";
import { Storage } from "../storage/storage";

export class APIDataConverter {
  toLegalDocs(legalDocsResponse) {
    return {
      termsAndConditions: legalDocsResponse?.termsAndConditions ?? "",
      privacyPolicy: legalDocsResponse?.privacyPolicy ?? "",
    };
  }

  toAuthorization(authResponse) {
    let accessToken = authResponse.accessToken ?? null;
    let user = this.toUser(authResponse.sessionUser);
    Storage.setLoggedInUser(user);
    Storage.setUserToken(accessToken);
    // return {user, accessToken};
  }

  toSessionUser(sessionUser) {
    let user = this.toUser(sessionUser);
    updateSessionUserInfo(user);
    return user;
  }

  toUser(response) {
    let user = {};
    user.id = response.id;
    user.accountType =
      response.userType === "ADMIN"
        ? UserAccountType.admin
        : response.userType === "MANAGER"
        ? UserAccountType.manager
        : UserAccountType.staff;
    user.email = response.email;
    user.firstName = response.firstName;
    user.lastName = response.lastName;
    user.isEmailVerified = response.isEmailVerified;
    return user;
  }

  toAvatar(response) {
    let avatar = {};
    avatar.thumb = response.thumbnailUrl;
    avatar.url = response.imageUrl ?? "";
    avatar.mimetype = response.mimetype ?? "";
    return avatar;
  }

  toPageInfo(pageInfo) {
    let isNextPageExist = pageInfo.currentPage < pageInfo.totalPages ? true : false;
    return {
      isNextPageExist: isNextPageExist,
      currentPage: pageInfo.currentPage,
      totalPages: pageInfo.totalPages,
      totalItems: pageInfo.totalItems,
    };
  }

  toCard(response) {
    let card = {};
    card.id = response.id;
    card.company = response.brand;
    card.expMonth = response.expMonth;
    card.expYear = response.expYear;
    card.last4 = response.last4;
    card.isDefault = response.isDefault;
    return card;
  }

  toPropertyList(response) {
    let list = response.list;
    let pageInfo = this.toPageInfo(response.pageInfo);
    return { list, pageInfo };
  }

  toServiceList(response) {
    let list = [];
    for (let i = 0; i < response.list.length; i++) {
      let value = response.list[i];
      list.push(this.toService(value));
    }
    return list;
  }

  toService(response) {
    let item = {
      id: response.id,
      label: response.name,
      value: response.name,
    };
    return item;
  }
}

/////// UPDATE SESSION USER /////////
function updateSessionUserInfo(user) {
  let info = Storage.getLoggedInUser() ?? {};
  info.user = user;
  Storage.setLoggedInUser(info);
}
