import { apiService } from "../services/api";
import { APIServicePath } from "../services/api/api-service-impl";

export const saveFaceToFaceData = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceSave, params)
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getFaceToFaceList = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getFaceToFaceMyList = (search, locationId, type, callback) => {
  let params = {
    search: search,
    locationIds: locationId ? [locationId] : null,
    listType: type,
  };
  apiService
    .postCall(APIServicePath.faceToFaceMyList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const getFaceToFaceBookingDetails = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceBookingDetails, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const bookFaceToFace = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceBooking, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getFaceToFaceModrateBooking = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceModrateBooking, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const uploadF2FCertificate = (file, callback) => {
  apiService
    .fileUploader(file, "F2F_COURSE_CERTIFICATE", APIServicePath.fileUpload)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const faceToFaceBookingByManagerApi = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceBookingByManager, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const removeFaceToFace = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceCancel, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const cancelBookingByLearner = (params, callback) => {
  apiService
    .postCall(APIServicePath.faceToFaceBookingCancel, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export function courseDropDown(isFetchAll, callback) {
  apiService
    .postCall(APIServicePath.courseList, { isAllowAll: isFetchAll })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
