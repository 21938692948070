import { RSelect } from "../../components/Component";
import React, { useEffect, useState } from "react";
import { managerDropDown } from "./MessageData";

export const ManagerDropDown = ({
  value,
  isMulti = false,
  isShowAll = true,
  onSelect,
  placeholder,
  isFetchAll = false,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    fetchManagerList();
  }, []);

  const fetchManagerList = () => {
    managerDropDown((data, error) => {
      if (error === null) {
        setList([...data]);
      }
    });
  };
  const onclear = () => {
    onSelect(null);
  };
  return (
    <>
      <RSelect
        options={list ?? []}
        isMulti={isMulti}
        placeholder={"Select Manager"}
        value={value}
        onChange={(e) => {
          onSelect(e);
        }}
        {...props}
      />
      {value && (
        <span onClick={() => onclear()} className="cursor-pointer d-flex justify-content-end text-primary">
          clear
        </span>
      )}
    </>
  );
};
