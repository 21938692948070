import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockTitle,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  Row,
  Button as CButton,
  TooltipComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import { useEffect } from "react";
import moment from "moment";
import AddFaceEvent from "./AddFaceEvent";
import {
  cancelBookingByLearner,
  getFaceToFaceBookingDetails,
  getFaceToFaceModrateBooking,
  removeFaceToFace,
} from "./FaceToFaceData";
import Swal from "sweetalert2";
import BookForStaff from "./BookForStaff";
import exportFromJSON from "export-from-json";
import ModerateBookingModal from "./ModerateBookingModal";
import { Storage } from "../services/storage/storage";
import { AppText, FTFBookingStatus, showErrorToast, showSuccessToast, toCapitalize } from "../utils";
import { FaceToFaceDuration, newlineText, openLinkInNewTab, UserAccountType } from "../utils/Utils";

export const BookingStatusType = {
  attended: "ATTENDED",
  absent: "ABSENT",
};

const DateEvent = () => {
  const [bookingListStatus, setBookingListStatus] = useState([]);
  const [modal, setModal] = useState(false);
  const [bookStaffmodal, setBookStaffmodal] = useState(false);
  const stateLocation = useLocation();
  const [trainingId, setTrainingId] = useState();
  const [isCancelBooking, setIsCancelBooking] = useState(false);
  const [isModerateBookingModalOpen, setIsModerateBookingModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [trainingData, setTrainingData] = useState();
  const navigate = useNavigate();
  const user = Storage.getLoggedInUser();

  useEffect(() => {
    let data = stateLocation.state?.trainingData;
    if (data) {
      setTrainingData(data);
      fetchFaceToFaceBookingList(data.id);
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchFaceToFaceBookingList(id) {
    const params = {
      id: id,
    };
    getFaceToFaceBookingDetails(params, (data, error) => {
      if (error === null) {
        setTrainingData((prev) => ({
          ...prev,
          dateTime: data?.data?.dateTime ?? prev.dateTime,
          totalBooking: data?.data?.totalBooking ?? prev.totalBooking,
          totalSeats: data?.data?.totalSeats ?? prev.totalSeats,
          title: data?.data?.title ?? prev.title,
          isGlobal: data?.data?.isGlobal,
          isZoom: data?.data?.isZoom,
          course: data?.data?.course ?? prev.course,
          location: data?.data?.location ?? prev.location,
          isEditable: data?.data?.isEditable,
          userData: data?.data?.userData,
          zoomData: data?.data?.zoomData,
        }));
        setBookingListStatus(data?.list);
        setIsCancelBooking(false);
      }
    });
  }

  const onRemoveClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove FTF training?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, I want!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = { id: trainingData.id };
        removeFaceToFace(params, (data, error) => {
          if (error === null) {
            showSuccessToast("Your FTF training is canceled successfully");
            navigate(-1);
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });
  };

  function onDownloadEmails() {
    const exportType = exportFromJSON.types.csv;
    let time = moment(trainingData?.dateTime ?? "").format("DD-MM-YYYY-HH-mm-A");

    const fileName = `Emails_${trainingData?.title ?? ""}_${time}`;
    let list = [];
    for (let index = 0; index < bookingListStatus?.length ?? 0; index++) {
      let item = bookingListStatus[index];
      if (item.status === BookingStatusType.attended) {
        list.push({
          Name: toCapitalize(item?.user?.firstName ?? "") + " " + toCapitalize(item?.user?.lastName ?? ""),
          Location: item?.user?.homeLocation?.name ?? "",
          Email: item.user?.email ?? "",
          Role: item.user?.role?.name ?? "",
        });
      }
    }
    exportFromJSON({ data: list, fileName: fileName, exportType: exportType });
  }

  // const BookingStatusType = {
  //   attended: "ATTENDED",
  //   absent: "ABSENT",
  // };

  const onBookingCancel = (bookingId, item) => {
    Swal.fire({
      title: "Are you sure?",
      text: item
        ? `Are you sure for the cancel face to face training for ${item.user.firstName}?`
        : `Are you sure for the cancel face to face training?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, I want!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsCancelBooking(true);
        cancelBookingByLearner({ ftfId: trainingData.id, bookingId: bookingId }, (data, error) => {
          if (error === null) {
            if (item) {
              // cancel by admin
              fetchFaceToFaceBookingList(trainingData.id);
            } else {
              // cancel by learner
              navigate(-1);
            }
          } else {
            showErrorToast(error.message?.length > 0 ? error.message : AppText.connectError);
            setIsCancelBooking(false);
          }
        });
      }
    });
  };

  function modrateBooking(item, index, status) {
    const actionText = status ? "mark present for" : "mark absent for";

    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${actionText} ${item.user.firstName} ${item.user.lastName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, I want!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        let statusText = status ? "ATTENDED" : "ABSENT";
        const params = {
          bookingId: item.id,
          status: statusText,
        };
        setTrainingId(item.id);
        getFaceToFaceModrateBooking(params, (data, error) => {
          if (error === null) {
            let list = [...bookingListStatus];
            item.status = statusText;
            list[index] = item;
            setBookingListStatus(list);
          } else {
            showErrorToast(error.message?.length > 0 ? error.message : AppText.connectError);
          }
          setTrainingId();
        });
      }
    });
  }

  let date = moment(trainingData?.dateTime ?? "")
    .add(trainingData?.duration ?? 0, "minutes")
    .toDate();
  let isOldFTF = date < new Date();

  let startDate = moment(trainingData?.dateTime ?? "").toDate();
  let isStarted = startDate <= new Date();

  let duration = FaceToFaceDuration.find((item) => item.value === trainingData?.duration);

  // const showStaffClick = (item) => {
  //   if (
  //     user?.accountType === UserAccountType.admin ||
  //     trainingData?.isEditable === true
  //   ) {
  //     let currentUser = Storage.getLoggedInUser();
  //     if (
  //       currentUser &&
  //       ((currentUser.id !== item?.user?.id &&
  //         item.userType !== UserAccountType.admin) ||
  //         currentUser.accountType === UserAccountType.admin)
  //     ) {
  //       history.push({
  //         pathname: `${process.env.PUBLIC_URL}/staff-training`,
  //         state: { id: item.user.id },
  //       });
  //     } else {
  //       showErrorToast(
  //         "You can not able to view and modify your own or admin or other location staff profile"
  //       );
  //     }
  //   } else {
  //     showErrorToast(
  //       "You can not able to view and modify your own or admin or other location staff profile"
  //     );
  //   }
  // };

  return (
    <React.Fragment>
      <Head title="add-new-training" />
      <Content>
        <div className="wide-md mx-auto">
          <BlockHead size="sm">
            <div className="row d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
              <div className="col-md-6">
                <BlockTitle tag="h3" page>
                  {toCapitalize(trainingData?.title ?? "")}
                </BlockTitle>
              </div>
              {/* {trainingData?.isGlobal === true ||
              ((user?.accountType === UserAccountType.admin ||
                trainingData?.isEditable === true) &&
                isOldFTF === false) ? ( */}
              {(trainingData?.isGlobal === true || trainingData?.isEditable === true) && isOldFTF === false ? (
                <div className="text-md-end flex-wrap mt-md-0 col-md-6">
                  <Button
                    color="primary"
                    className="btn-text custom-solid-btn-sm m-1"
                    onClick={() => setBookStaffmodal(true)}
                  >
                    Book for Staff
                  </Button>
                  {(user?.accountType === UserAccountType.admin || trainingData?.isEditable === true) && (
                    <>
                      <Button
                        color="primary"
                        className="btn-text custom-solid-btn-sm m-1"
                        onClick={() => setModal(true)}
                      >
                        Edit Training
                      </Button>
                      <Button
                        color="primary"
                        className="btn-text custom-solid-btn-sm m-1"
                        onClick={() => onRemoveClick()}
                      >
                        Remove
                      </Button>
                    </>
                  )}
                </div>
              ) : user?.accountType !== UserAccountType.staff && isOldFTF && bookingListStatus?.length > 0 ? (
                <div className="text-md-end flex-wrap mt-md-0 col-md-6">
                  <Button
                    color="primary"
                    className="btn-text custom-solid-btn-sm m-1"
                    onClick={() => onDownloadEmails()}
                  >
                    Download Email's
                  </Button>
                </div>
              ) : null}
              {user?.accountType === UserAccountType.staff && (
                <>
                  <div className="text-md-end flex-wrap custom-margin-top mt-md-0 col-md-6">
                    {trainingData?.myBooking?.status === FTFBookingStatus.applied && (
                      <Button
                        color="light"
                        outline
                        className="bg-white d-sm-inline-flex me-2"
                        onClick={() => onBookingCancel(trainingData?.myBooking?.id)}
                        disabled={isCancelBooking}
                      >
                        <span>{isCancelBooking ? <Spinner size="sm" color="dark" /> : "Cancel"}</span>
                      </Button>
                    )}
                    <Button color="light" outline className="bg-white d-sm-inline-flex" onClick={() => navigate(-1)}>
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                  </div>
                  {/* <a
                    href="#back"
                    onClick={(ev) => {
                      ev.preventDefault();
                      history.goBack();
                    }}
                    className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                  >
                    <Icon name="arrow-left"></Icon>
                  </a> */}
                </>
              )}
            </div>
          </BlockHead>
          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <div className="card-inner">
                    <Block>
                      <Row className="w-100">
                        <Col sm="6" className="p-3 pe-sm-36 border-end border-none-asm">
                          <Row>
                            <Col className="col-5">
                              <span className="profile-ud-label">Date / Time</span>
                            </Col>
                            <Col className="col-7 text-end">
                              <span className="profile-ud-value fw-bold">
                                {moment(trainingData?.dateTime ?? "").format("DD-MM-YYYY / HH:mm A")}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="6" className="p-3 ps-sm-36">
                          <Row>
                            <Col className="col-5">
                              <span className="profile-ud-label">
                                {user?.accountType === UserAccountType.staff && !isOldFTF ? "Available seats" : "Seats"}
                              </span>
                            </Col>
                            <Col className="col-7 text-end">
                              {user?.accountType === UserAccountType.staff ? (
                                <span className="profile-ud-value fw-bold">
                                  {isOldFTF ? (
                                    <>{trainingData?.totalSeats ?? 0}</>
                                  ) : (
                                    <>{(trainingData?.totalSeats ?? 0) - (trainingData?.totalBooking ?? 0)}</>
                                  )}
                                </span>
                              ) : (
                                <span className="profile-ud-value fw-bold">
                                  {trainingData?.totalBooking ?? "0"} / {trainingData?.totalSeats ?? "0"}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="6" className="p-3 pe-sm-36 border-end border-none-asm">
                          <Row>
                            <Col className="col-5">
                              <span className="profile-ud-label">Location</span>
                            </Col>
                            <Col sm="7" className=" col-7 text-end">
                              <span className="profile-ud-value fw-bold">
                                {trainingData?.isZoom ? "Zoom training" : trainingData?.location?.name ?? ""}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="6" className="p-3 ps-sm-36">
                          <Row>
                            <Col className="col-5">
                              <span className="profile-ud-label">Duration</span>
                            </Col>
                            <Col className=" col-7 text-end">
                              <span className="profile-ud-value fw-bold">{duration?.label ?? ""}</span>
                            </Col>
                          </Row>
                        </Col>
                        {trainingData?.isZoom && (
                          <Col className="col-12 p-3 ">
                            <Row className="">
                              <Col className="col-4 col-md-2 ">
                                <span className="profile-ud-label">Zoom detail</span>
                              </Col>
                              <Col className="col-8 col-md-10  ">
                                <span className="profile-ud-value word-wrap">
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href={undefined}
                                    onClick={(ev) => {
                                      openLinkInNewTab(trainingData?.zoomData?.joinUrl, ev);
                                    }}
                                    className="text-azure cursor"
                                  >
                                    {trainingData?.zoomData?.joinUrl ?? ""}
                                  </a>
                                  <br />
                                  <p className="mb-0">
                                    {"Meeting Id : " + (trainingData?.zoomData?.meetingId ?? "N/A")}
                                  </p>
                                  <p>{"Passcode : " + (trainingData?.zoomData?.password ?? "N/A")}</p>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col className="col-12 p-3 ">
                          <Row className="">
                            <Col className="col-4 col-md-2 ">
                              <span className="profile-ud-label">Description</span>
                            </Col>
                            <Col className="col-8 col-md-10">
                              <span className="profile-ud-value word-wrap">
                                {newlineText(trainingData?.description ?? "")}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Block>
                  </div>
                </div>
              </div>
            </Card>
          </Block>
          {user?.accountType !== UserAccountType.staff && (
            <Block>
              <DataTable className="card-stretch">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text">Staff name</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Email</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="sub-text">Role</span>
                    </DataTableRow>
                    {user?.accountType === UserAccountType.admin ? (
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <span className="sub-text">Action</span>
                      </DataTableRow>
                    ) : (
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <span className="sub-text">Present</span>
                      </DataTableRow>
                    )}
                  </DataTableHead>
                  {/*Head*/}
                  {bookingListStatus.length > 0
                    ? bookingListStatus.map((item, index) => {
                        return (
                          <DataTableItem key={index}>
                            <DataTableRow>
                              <div
                                className="user-info"
                                // className="user-info cursor"
                                // onClick={(e) => {
                                //   e.preventDefault();
                                //   showStaffClick(item);
                                // }}
                              >
                                <span className="tb-lead mt-1">
                                  {toCapitalize(item?.user?.firstName ?? "")} {toCapitalize(item?.user?.lastName ?? "")}
                                  {""}
                                </span>
                                <p className="text-gray">{item?.user?.homeLocation?.name ?? " "}</p>
                              </div>
                            </DataTableRow>
                            <DataTableRow>
                              <div className="user-info">
                                <span className="tb-lead">{item.user?.email ?? " "}</span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <div className="user-info">
                                <span className="tb-lead">{item.user?.role?.name ?? " "} </span>
                              </div>
                            </DataTableRow>

                            {user?.accountType === UserAccountType.admin || trainingData?.isEditable === true ? (
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <ul className="nk-tb-actions gx-1">
                                  {item.status === BookingStatusType.absent ||
                                  item.status === BookingStatusType.attended ? (
                                    <span
                                      className={`${
                                        item.status === "ATTENDED" ? " bg-success" : "bg-danger"
                                      } badge badge-dim text-dark fs-0_775rem py-1 px-2`}
                                    >
                                      {toCapitalize(item.status)}
                                    </span>
                                  ) : (
                                    <>
                                      {trainingId && trainingId === item.id ? (
                                        <Spinner size="sm" color="primary" />
                                      ) : (
                                        <>
                                          {isStarted === false ? (
                                            <CButton
                                              className={`btn-text text-danger mx-2 p-0 fw-normal`}
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                onBookingCancel(item.id, item);
                                              }}
                                            >
                                              Cancel
                                            </CButton>
                                          ) : (
                                            <>
                                              <li onClick={() => modrateBooking(item, index, false)}>
                                                <TooltipComponent
                                                  tag="a"
                                                  containerClassName="btn btn-trigger btn-icon"
                                                  iconClass={"text-danger"}
                                                  id={"absent" + item.id}
                                                  icon="cross-fill-c"
                                                  direction="top"
                                                  text="Absent"
                                                />
                                              </li>
                                              <li
                                                // onClick={() =>
                                                //   modrateBooking(
                                                //     item,
                                                //     index,
                                                //     true
                                                //   )
                                                // }
                                                onClick={() => {
                                                  setIsModerateBookingModalOpen(true);
                                                  setSelectedItem(item);
                                                  setSelectedIndex(index);
                                                }}
                                              >
                                                <TooltipComponent
                                                  tag="a"
                                                  containerClassName="btn btn-trigger btn-icon"
                                                  iconClass={"text-success"}
                                                  id={"present" + item.id}
                                                  icon="check-fill-c"
                                                  direction="top"
                                                  text="Present"
                                                />
                                              </li>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </ul>
                              </DataTableRow>
                            ) : (
                              <DataTableRow>
                                <div className="user-info text-end">
                                  <span
                                    className={`${
                                      item.status === "ATTENDED"
                                        ? " bg-success"
                                        : item.status === "ABSENT"
                                        ? "bg-danger"
                                        : "bg-info"
                                    } badge badge-dim text-dark fs-0_775rem py-1 px-2`}
                                  >
                                    {item.status === "APPLIED" ? "Booked" : toCapitalize(item.status)}
                                  </span>
                                </div>
                              </DataTableRow>
                            )}
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
                {bookingListStatus.length === 0 && (
                  <div className="card-inner">
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  </div>
                )}
              </DataTable>
            </Block>
          )}
        </div>
      </Content>
      {(user?.accountType === UserAccountType.admin || trainingData?.isEditable === true) && (
        <AddFaceEvent
          dataInfo={trainingData}
          visible={modal}
          onClosed={(item) => {
            setModal(false);
            setTrainingData((prev) => ({
              ...prev,
              ...item,
            }));
          }}
        />
      )}
      {user?.accountType !== UserAccountType.staff && (
        <BookForStaff
          dataInfo={trainingData}
          visible={bookStaffmodal}
          onClosed={(item) => {
            setBookStaffmodal(false);
            setTrainingData((prev) => ({
              ...prev,
              ...item,
            }));
          }}
          onSuccess={() => {
            fetchFaceToFaceBookingList(trainingData.id);
          }}
        />
      )}

      <ModerateBookingModal
        visible={isModerateBookingModalOpen}
        trainingData={trainingData}
        item={selectedItem}
        onClosed={() => setIsModerateBookingModalOpen(false)}
        onSuccess={() => {
          let list = [...bookingListStatus];
          selectedItem.status = BookingStatusType.attended;
          list[selectedIndex] = selectedItem;
          setBookingListStatus(list);
        }}
      />
    </React.Fragment>
  );
};

export default DateEvent;
