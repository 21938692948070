import React, { useEffect, useRef } from "react";
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "../../../../components/Component";
import { useState } from "react";

import { Editor } from "@tinymce/tinymce-react";
import AddTest from "./AddTest";
import { QuestionListView } from "./CourseTest";

const AddChapter = ({ visible, isIntro, item, onSaved, onClosed }) => {
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const [value, setValue] = useState();
  const [questions, setQuestions] = useState([]);

  const [isOpenAddQuestion, setIsOpenAddQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState();

  useEffect(() => {
    if (visible) {
      setError();
      setTitle("");
      setValue();
      setQuestions([]);
    }
  }, [visible]);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setValue(item.html);
      setQuestions(item?.questions ?? []);
    }
  }, [item]);

  const handleSave = () => {
    if (!title && !isIntro) {
      setError("Title is required.");
      return;
    } else if (value === null || value === undefined) {
      setError("Please enter your chapter.");
      return;
    }
    if (editorRef?.current) {
      onSaved({ title: title, html: value, questions: questions });
      onClosed();
    }
  };

  const handleEditQuestion = (item, index) => {
    setSelectedQuestion(item);
    setSelectedQuestionIndex(index);
    setIsOpenAddQuestion(true);
  };

  const handleRemoveQuestion = (value) => {
    let list = (questions ?? []).filter((item) => item !== value);
    setQuestions(list);
  };

  return (
    <>
      <Modal isOpen={visible} toggle={() => onClosed()} className="modal-dialog-centered" size="xl">
        <div className="modal-header">
          <h5 className="modal-title">{isIntro ? "Introduction" : item ? "Edit chapter" : "Add chapter"}</h5>
          <div className="text-end flex-fill ">
            <Button
              color="primary"
              className="custom-solid-btn-sm text-end me-2"
              onClick={() => setIsOpenAddQuestion(true)}
            >
              Add question
            </Button>
            <Button
              className="btn-close p-1"
              // aria-label="Close"
              onClick={() => onClosed()}
            />
          </div>
        </div>

        <ModalBody>
          <FormGroup>
            {!isIntro && (
              <>
                <Label for="title">Title</Label>
                <Input
                  className="mb-2"
                  type="text"
                  name="title"
                  id="title"
                  maxLength={255}
                  value={title ?? ""}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {error && <p className="text-danger">{error}</p>}
              </>
            )}
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={value ?? ""}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              onEditorChange={(newText) => setValue(newText)}
              init={{
                menubar: false,
                statusbar: false,
                height: 450,
                automatic_uploads: false,
                file_picker_callback: function (callback, value, meta) {
                  // Provide file and text for the link dialog
                  if (meta.filetype === "file") {
                    callback("mypage.html", { text: "My text" });
                  }

                  // Provide image and alt text for the image dialog
                  if (meta.filetype === "image") {
                    callback("myimage.jpg", { alt: "My alt text" });
                  }

                  // Provide alternative source and posted for the media dialog
                  if (meta.filetype === "media") {
                    callback("movie.mp4", {
                      source2: "alt.ogg",
                      poster: "image.jpg",
                    });
                  }
                },
                fontsize_formats:
                  "8px 9px 10px 11px 12px 14px 15px 16px 17px 18px 19px 20px 24px 30px 36px 48px 60px 72px 96px",
                paste_data_images: true,
                // images_upload_url: "postAcceptor.php",

                /* we override default upload handler to simulate successful upload*/
                images_upload_handler: function (blobInfo, success, failure) {
                  setTimeout(function () {
                    success();
                  }, 2000);
                },
                content_style: "body { font-size: 14pt; }",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code",
                ],
                toolbar1:
                  "preview | undo redo | formatselect | fontsizeselect |  fontselect  | " +
                  "bold italic underline | forecolor | strikethrough superscript subscript | code | alignleft aligncenter " +
                  "alignright alignjustify | lineheight | forecolor | backcolor | outdent indent | numlist bullist | link image media| removeformat | " +
                  "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 35px 36px",
              }}
            />
          </FormGroup>
          {questions.length > 0 && (
            <QuestionListView questions={questions} onEdit={handleEditQuestion} onRemove={handleRemoveQuestion} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="custom-solid-btn-sm" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      {isOpenAddQuestion && (
        <AddTest
          visible={isOpenAddQuestion}
          item={selectedQuestion}
          onClosed={() => {
            setIsOpenAddQuestion(false);
            setSelectedQuestion();
            setSelectedQuestionIndex();
          }}
          onSaved={(item) => {
            let list = [...(questions ?? [])];
            if (selectedQuestion && (selectedQuestionIndex ?? 0) >= 0) {
              list[selectedQuestionIndex] = item;
            } else {
              list.push(item);
            }
            setQuestions(list);
          }}
        />
      )}
    </>
  );
};

export default AddChapter;
