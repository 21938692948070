import React from "react"; // Replace 'your-ui-library' with the actual UI library you are using
import { useNavigate } from "react-router";
import Button from "./Button";
import { Icon } from "../Component";
const BackButton = ({ cancel, onClick }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Button
        color="light"
        type="button"
        outline
        className="bg-white d-sm-inline-flex"
        onClick={onClick ?? handleGoBack}
      >
        {!cancel ? (
          <>
            <Icon name="arrow-left" />
            <span>Back</span>
          </>
        ) : (
          "Cancel"
        )}
      </Button>

      {/* <a
        href="#back"
        onClick={(ev) => {
          ev.preventDefault();
          handleGoBack();
        }}
        className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
      >
        <Icon name="arrow-left" />
      </a> */}
    </>
  );
};

export default BackButton;
