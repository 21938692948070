import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Col, Icon } from "../../../../components/Component";
import { QuestionType } from "../../../utils";

const AddTest = ({ visible, item, onSaved, onClosed }) => {
  const [options, setOptions] = useState([""]);
  const [answerIndexes, setAnswerIndexes] = useState([]);
  const addOption = () => {
    setOptions([...options, ""]);
  };
  const [formData, setFormData] = useState({ question: "", questionType: QuestionType.mcq });

  useEffect(() => {}, [formData]);

  const { errors } = useForm();

  useEffect(() => {
    if (visible) {
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (item) {
      setFormData({
        ...formData,
        id: item.id,
        question: item?.question,
        questionType: item?.questionType,
      });
      setOptions(item.options);
      setAnswerIndexes(item.answerIndexes);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const reset = () => {
    setFormData({ ...formData, question: "", questionType: QuestionType.mcq });
    setOptions([""]);
    setAnswerIndexes([]);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const onFormSubmit = () => {
    const { id, questionType, question } = formData;
    if (options.length >= 2 && answerIndexes.length > 0) {
      let data = {
        id: id,
        question: question,
        questionType: questionType,
        options: options,
        answerIndexes: answerIndexes,
      };
      onSaved(data);
      onClosed();
    }
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setAnswerIndexes([]);
    if (value === QuestionType.mcq || value === QuestionType.msq) {
      setOptions([""]);
    } else if (value === QuestionType.tf) {
      setOptions(["True", "False"]);
    }
  };

  const onInputQuestionChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onOptionChange = (e, index) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    setOptions(newOptions);
  };

  return (
    <>
      <Modal isOpen={visible} toggle={() => onClosed()} className="modal-dialog-centered" size="md">
        <ModalHeader toggle={onClosed}>{item ? "Update test" : "Test"}</ModalHeader>
        <ModalBody>
          <Form className="row gy-4">
            <Col size="12">
              <div className="form-group">
                <label className="mb-1">Question Type:</label>
                <div>
                  <label className="me-3">
                    <input
                      className="me-1"
                      type="radio"
                      name="questionType"
                      value={QuestionType.mcq}
                      checked={formData?.questionType === QuestionType.mcq}
                      onChange={onInputChange}
                    />
                    MCQ
                  </label>
                  <label className="me-3">
                    <input
                      className="me-1"
                      type="radio"
                      name="questionType"
                      value={QuestionType.msq}
                      checked={formData?.questionType === QuestionType.msq}
                      onChange={onInputChange}
                    />
                    MSQ
                  </label>
                  <label>
                    <input
                      className="me-1"
                      type="radio"
                      name="questionType"
                      value={QuestionType.tf}
                      checked={formData?.questionType === QuestionType.tf}
                      onChange={onInputChange}
                    />
                    True or False
                  </label>
                </div>
              </div>
            </Col>
            <Col size="12">
              <div className="form-group">
                <label className="mb-1">Question:</label>
                <textarea
                  name="question"
                  value={formData?.question}
                  placeholder="Enter Question"
                  onChange={onInputQuestionChange}
                  className="form-control-xl form-control no-resize"
                />
                {errors?.question && <span className="invalid">{errors.question.message}</span>}
              </div>
            </Col>
            {options.length > 0 && (
              <>
                {options.map((option, index) => (
                  <Col size="12" key={index}>
                    <div className="form-group d-flex align-items-center">
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={`option${index}`}
                          id={`option${index}`}
                          checked={answerIndexes.some((e) => e === index)}
                          onChange={() => {
                            if (formData?.questionType === QuestionType.msq) {
                              if (answerIndexes.some((e) => e === index)) {
                                setAnswerIndexes(answerIndexes.filter((e) => e !== index));
                              } else {
                                let list = [...answerIndexes];
                                list.push(index);
                                setAnswerIndexes(list);
                              }
                            } else {
                              setAnswerIndexes([index]);
                            }
                          }}
                        />
                        <label className="form-check-label" htmlFor={`option${index}`}></label>
                      </div>
                      <Input
                        className="w-75"
                        type="text"
                        name={`option${index}`}
                        placeholder={`Option ${index + 1}`}
                        value={option}
                        disabled={formData?.questionType === QuestionType.tf}
                        onChange={(e) => onOptionChange(e, index)}
                      />
                      {index > 0 && formData?.questionType === QuestionType.mcq && (
                        <Button type="button" onClick={() => removeOption(index)}>
                          <Icon name="minus" />
                        </Button>
                      )}
                      {index > 0 && formData?.questionType === QuestionType.msq && (
                        <Button type="button" onClick={() => removeOption(index)}>
                          <Icon name="minus" />
                        </Button>
                      )}
                      <div className="ms-auto">
                        {index >= options.length - 1 && formData?.questionType === QuestionType.mcq && (
                          <Button type="button" onClick={addOption}>
                            <Icon name="plus" />
                          </Button>
                        )}
                        {index >= options.length - 1 && formData?.questionType === QuestionType.msq && (
                          <Button type="button" onClick={addOption}>
                            <Icon name="plus" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="custom-solid-btn-sm" onClick={() => onFormSubmit()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddTest;
