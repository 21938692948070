/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interaction from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import moment from "moment";
import { FaceToFaceDuration } from "../../../pages/utils/Utils";

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  let duration = FaceToFaceDuration.find((item) => item.value === extendedProps?.object?.duration);
  return (
    <React.Fragment>
      <div
        className=""
        id={publicId}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>
          <span>Time : {moment(extendedProps?.object?.dateTime).format("HH:mm A")}</span>
          <br />
          <span>Duration : {duration?.label ?? ""}</span>
          <br />
          <span>Location : {extendedProps?.object?.location?.name ?? "Zoom"}</span>
        </PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

const CalenderApp = ({ events, onEvent, onAdd, onDateChange }) => {
  const [mockEvents, updateEvents] = useState(events);
  const [edit, updateEditModal] = useState(false);

  useEffect(() => {
    updateEvents(events);
  }, [events]);

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  const handleEventClick = (info) => {
    onEvent(info.event._def.extendedProps.object);
  };

  return (
    <React.Fragment>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interaction]}
        events={mockEvents}
        eventClick={(info) => handleEventClick(info)}
        datesSet={(dates) => onDateChange(dates.start, dates.end)}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "title prev,next",
          center: null,
          right: "today dayGridMonth,listMonth",
        }}
        themeSystem="bootstrap5"
        height={800}
        contentHeight={780}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={true}
        droppable={true}
        dateClick={(info) => onAdd(info)}
        backgroundColor="#1a7dff"
      />
    </React.Fragment>
  );
};

export default CalenderApp;
