/* eslint-disable no-unused-vars */
import { Step, Steps } from "react-step-builder";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  PreviewAltCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import AddStaffTraining from "./AddStaffTraining";
import AssignTraining from "./AssignTraining";
import React, { createContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { useState } from "react";
export const AddTrainingWizardContext = createContext();

const Header = (props) => {
  return (
    <div className="steps clearfix">
      <ul>
        <li className={(props.current >= 1 ? "first done" : "first") + " wizard-margin"}>
          <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Info</span>
          </a>
        </li>
        <li className={(props.current >= 2 ? "done" : "") + " wizard-margin"}>
          <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Outcome</span>
          </a>
        </li>
        <li className={props.current >= 3 ? "done" : ""}></li>
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};
const TrainingWizard = () => {
  const location = useLocation();
  const [data, setData] = useState({
    courseListData: location.state.data.list,
    optionalButCompletedCourses: location.state.data.optionalList,
    userData: location.state.user,
  });
  // let optionalButCompletedCourses = location.state.data;

  const addTrainingWizardContext = useMemo(
    () => ({
      data: data,
      updateData: (key, value) => {
        setData((prev) => ({
          ...prev,
          [key]: value,
        }));
      },
    }),
    [data]
  );

  return (
    <React.Fragment>
      <Head title="Training" />
      <Content>
        <Block className="wide-md mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Select training for{" "}
                  {(location.state?.user?.firstName ?? "") + " " + (location.state?.user?.lastName ?? "")}
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>Training that has already been assigned is in teal.Click on 'load more' to see all courses</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewAltCard>
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <AddTrainingWizardContext.Provider value={addTrainingWizardContext}>
                <Steps config={config}>
                  <Step component={AddStaffTraining} />
                  <Step component={AssignTraining} />
                </Steps>
              </AddTrainingWizardContext.Provider>
            </div>
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TrainingWizard;
