import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Storage } from "../services/storage/storage";
import { apiService } from "../services/api";

const auth = Storage.getUserToken();
apiService.setUserToken(auth);

const PrivateRoute = () => {
  return auth ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/auth-login`} />;
};

export default PrivateRoute;
