import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

const Faq = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="FAQ" />
      <Content>
        <div className="help">
          <div class="container">
            <h2>Help</h2>
            <p>
              The Saunders Training Academy Platform has been designed with ease of use and navigation in mind. Please
              ensure you complete the short training entitled ‘Saunders Training Academy user guide’ which will help you
              learn how to navigate around the platform and with troubleshooting for any difficulties you may encounter.
            </p>
            <p>
              If you cannot find the answers to your query within the user guide training, you may find them below in
              ‘frequently asked questions’.
            </p>
            <p>
              If after reading ‘frequently asked questions’ you are not able to find the answer to your query then
              please email <a href="support@saunderstrainingacademy.co.uk">support@saunderstrainingacademy.co.uk</a> and
              someone from the team will respond as soon as possible ( please allow 48-72hours for a response)
            </p>{" "}
            <br />
            <h3>Frequently Asked questions</h3> <br />
            <p>
              <b>I am unable to login, why is this?</b>
            </p>
            <p>There may be a few reasons why you are unable to login to Saunders Training Academy:</p>
            <ul>
              <li>
                You are not registered or have been put on hold/ removed from the platform by your manager. Please speak
                with your manager to check you are an active learner on Saunders Training Academy.
              </li>
              <li>
                You have forgotten or are inputting the incorrect password. Please click ‘password reset’ and enter your
                email address. This will generate an automatic email with a new temporary password. Once this is
                received, please go back to the login page, input your email and the new password. Once logged in please
                change your password to one that is personal and secure to you.
              </li>
            </ul>
            <p>
              <b>How do I change my email address?</b>
            </p>
            <p>
              If you have changed your email address, please contact{" "}
              <a href="support@saunderstrainingacademy.co.uk">support@saunderstrainingacademy.co.uk</a> and ask them to
              change your email address on Saunders training academy. In order to make the change please provide your
              full name, work location and your new email address. Once this has been changed you can log in using the
              new email and your normal password.
            </p>
            <p>
              <b>How do I change my password?</b>
            </p>
            <p>
              Log in to Saunders Training Academy as normal. Once logged in click on the blue icon at the top right of
              the screen and then select change password.
            </p>
            <br />
            <p>
              <b>When I select a training, why is the ‘join now’ button not working?</b>
            </p>
            <p>
              The only reason you may not be able to fully access a training or the ‘join now’ button isn’t visible, or
              working is due to the browser you are using. Please ensure you are using ‘chrome’ as your web browser when
              accessing Saunders Training Academy and that cookies are enabled. If this doesn’t not resolve the problem,
              please contact <a href={() => false}>support@saunderstrainingacademy.co.uk</a>.
            </p>
            <br />
            <p>
              <b>Why are the videos not working when I play them?</b>
            </p>
            <p>
              Please ensure you are using ‘chrome’ as your web browser when accessing Saunders Training Academy. If this
              doesn’t not resolve the problem, please contact{" "}
              <a href={() => false}>support@saunderstrainingacademy.co.uk</a>.
            </p>
            <br />
            <p>
              <b>How do I change the completion date of trainings?</b>
            </p>
            <p>
              Learners are unable to change the completion date of their trainings, the date will be saved as per the
              date you completed the training on Saunders Training Academy with the exception of any practical trainings
              or the Deprivation of Liberty trainings. For these trainings please complete them and bring evidence of
              completion (training certificate) to your Manager who will manually change the date of completion on
              Saunders Training Academy.
            </p>
            <p>
              <b>I have failed my end of course test 3 times, how do I get the training reset?</b>
            </p>
            <p>
              A learner has 3 attempts to pass each end of course test, if after this the learner has not achieved the
              required 80% pass mark the training will be locked. If this happens you should select ‘send reset
              request’. This sends an automated email to your manager who will then reset your training. Please allow
              sufficient time for your manager to reset the training. Once the training has been reset you must complete
              the full training again and you will have another 3 attempts to pass the end of course test.
            </p>
            <p>
              <b>How do I cancel a place on a face-to-face training?</b>
            </p>
            <p>
              If you are no longer able to attend a face-to-face training, please log into Saunders Training Academy and
              cancel your place as per the steps below:
            </p>
            <ul>
              <li>Log in to Saunders training academy.</li>
              <li>go to f2f booking</li>
              <li>Go to booked trainings.</li>
              <li>Click on the name of the training.</li>
              <li>Clink cancel.</li>
              <li>Click yes in the pop-up box.</li>
            </ul>
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Faq;
