import React from "react";
import { Button, Icon, PreviewCard } from "../../../components/Component";
import { useState } from "react";
import { Badge, Progress } from "reactstrap";
import { useNavigate } from "react-router";

import { isCourseCompleted } from "./DueTrainingData";
import { sendUnlockCourseRequest } from "../Training/TrainingData";
import { dateToShowFormate, dueDateColor, showErrorToast, showSuccessToast } from "../../utils";
export const TrainingCard = ({ item, isMandatory }) => {
  const [isUnlockRequest, setIsUnlockRequest] = useState(item?.isUnlockRequest ?? 0);
  const navigate = useNavigate();

  const onStartClick = (isButtonTap) => {
    if (item.isRetakeBlock && isUnlockRequest === 0 && isButtonTap && item.isCompleted !== "COMPLETED") {
      sendRequestToManage();
    } else if (item.isCompleted === "IN_PROGRESS") {
      navigate(`${process.env.PUBLIC_URL}/start-course`, {
        state: { item: item, IsCourseProgress: true },
      });
    } else if (item.isEvaluationRequired === true && item.isEvaluationFormFilled === false && isButtonTap) {
      navigate(`${process.env.PUBLIC_URL}/start-course`, {
        state: { item: item, IsEvaluationFormMove: true },
      });
    } else if (item.isCompleted === "CHAPTER_COMPLETED" && isButtonTap) {
      navigate(`${process.env.PUBLIC_URL}/start-course`, {
        state: { item: item, IsTakeTestMove: true },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/start-course`, {
        state: { item: item },
      });
    }
  };

  const sendRequestToManage = () => {
    if (item?.id) {
      sendUnlockCourseRequest(item?.id, (data, error) => {
        if (error === null) {
          setIsUnlockRequest(1);
          showSuccessToast("Your request has been sent to your manager");
        } else {
          showErrorToast(error.message);
        }
      });
    }
  };

  let percentage =
    (item?.totalChaptersCount ?? 0) === 0
      ? 0
      : isCourseCompleted(item?.isCompleted, item?.isApprovalCertStatus, item?.dueInStartAt)
      ? 100
      : item.isCompleted === "CHAPTER_COMPLETED"
      ? 100
      : parseInt(((item?.totalCompletedChapters ?? 0) / (item?.totalChaptersCount ?? 0)) * 100);
  return (
    <React.Fragment>
      <div className="hello">
        <div onClick={() => onStartClick(false)}>
          <ImageContainer img={item?.image?.imageUrl} isMandatory={isMandatory} />
        </div>
        <PreviewCard className="card-bordered training-card ">
          <div className="project-head">
            <a
              href="#title"
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className="project-title"
            >
              <div className="project-info" onClick={() => onStartClick(false)}>
                <h6 className="title">{item?.name ?? ""}</h6>
                {/* <span className="sub-text fa-ring">{item?.name ?? ""}</span> */}
              </div>
            </a>
          </div>
          <div>
            <div className="project-progress">
              <div className="project-progress-details">
                <div className="project-progress-task text-body">
                  <Icon name="check-round-cut"></Icon>
                  <span>{item?.totalChaptersCount ?? 0} Chapters</span>
                </div>
                <div className="project-progress-percent">{percentage}%</div>
              </div>
              {item.isCompleted === "CHAPTER_COMPLETED" || item.isCompleted === "COMPLETED" ? (
                <>
                  {item?.isApprovalCertStatus ? (
                    <label className="text-danger text-end">
                      {item?.isApprovalCertStatus === "PENDING"
                        ? "Certificate Approval Pending"
                        : item?.isApprovalCertStatus === "REJECTED"
                        ? "Certificate Rejected"
                        : ""}
                    </label>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Progress className="progress-pill progress-md bg-light" value={percentage}></Progress>
              )}
            </div>
            <div className="project-meta mt-2">
              {item?.maxDueAt && isMandatory ? (
                <Badge
                  className="badge-dim text-dark fs-0_775rem py-1 px-2"
                  color={dueDateColor(item?.dueInStartAt, item?.maxDueAt)}
                >
                  <span>{dateToShowFormate(item?.maxDueAt ?? "")}</span>
                </Badge>
              ) : (
                <>&nbsp;</>
              )}

              {item?.isRetakeBlock && isUnlockRequest === 1 ? (
                <label className="text-danger text-end">Wait, unlock request sent</label>
              ) : (
                <Button color="primary" className="btn-text custom-solid-btn-sm" onClick={() => onStartClick(true)}>
                  {isCourseCompleted(item?.isCompleted, item?.isApprovalCertStatus, item?.dueInStartAt)
                    ? "View training"
                    : item.isRetakeBlock === true
                    ? "Send a reset request"
                    : item.isCompleted === "CHAPTER_COMPLETED"
                    ? item.isEvaluationRequired === true && item.isEvaluationFormFilled === false
                      ? "Evaluation Form"
                      : "Take test"
                    : item.isCompleted === "IN_PROGRESS"
                    ? "Continue training"
                    : "Start training"}
                </Button>
              )}
            </div>
          </div>
        </PreviewCard>
      </div>
    </React.Fragment>
  );
};

export const ImageContainer = ({ className, img, isMandatory }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className={`image-container ${className}`} style={{ position: "relative" }}>
      <a
        className="gallery-image popup-image"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        href="#gallery"
      >
        <img className="w-100 rounded-top" src={img} alt="" style={{ height: "200px", width: "300px" }} />
      </a>
      {isMandatory && (
        <div
          className="badge-container"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "1",
          }}
        >
          <Badge className=" text-dark fs-0_775rem py-1 px-2" color="light">
            Mandatory
          </Badge>
        </div>
      )}
    </div>
  );
};
