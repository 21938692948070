import React, { useEffect } from "react";
import Content from "../../../layout/content/Content";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { fetchAssessmentResultSave } from "./DueTrainingData";
import { Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import UploadManualCertificate from "./UploadManualCertificate";
import { QuestionType } from "../../utils";
import { Storage } from "../../services/storage/storage";

const AssesmentTest = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [courseTestQuestion] = useState(location?.state?.questionData);
  const [courseDetail] = useState(location?.state?.courseDetails);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useState(Storage.getLoggedInUser());
  const [error, setError] = useState({ error: false, errorText: "error" });
  const [isUploadCertificateVisiable, setIsUploadCertificateVisiable] = useState(false);

  window.onpopstate = (e) => {
    if (location?.state?.isTakeTestMoved) {
      navigate(`${process.env.PUBLIC_URL}/`);
    }
  };

  useEffect(() => {
    setIsFormValid(
      Object.keys(formData).length === courseTestQuestion?.length &&
        !Object.values(formData).some((item) => item.answers?.length === 0)
    );
  }, [formData, courseTestQuestion]);

  const handleOptionChange = (id, question, option) => {
    let result = formData[id];
    if (result && question.type === QuestionType.msq) {
      if (result.answers.some((item) => item === option)) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            questionId: id,
            answers: result.answers.filter((item) => item !== option),
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            questionId: id,
            answers: [...result.answers, option],
          },
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: {
          questionId: id,
          answers: [option],
        },
      }));
    }
  };

  const formSubmit = () => {};

  const saveResult = (fileId) => {
    setIsLoading(true);
    let params = {
      courseId: courseDetail.id,
      results: Object.values(formData),
      fileId: fileId,
    };

    fetchAssessmentResultSave(params, (data, error) => {
      if (error === null) {
        setIsLoading(true);
        if (data?.data?.result === "FAIL") {
          navigate(`${process.env.PUBLIC_URL}/training-assesment-result`, {
            state: {
              userData: userData,
              courseId: courseDetail.id,
              questionData: courseTestQuestion,
              courseDetails: courseDetail,
            },
          });
        } else {
          navigate(`${process.env.PUBLIC_URL}/training-certificate`, {
            state: {
              userData: userData,
              courseId: courseDetail.id,
              courseDetail: courseDetail,
              certificateUrl: data?.data?.certificateUrl,
              isAssesmentExist: true,
            },
          });
        }
      } else {
        setIsLoading(false);
        setError({ error: true, errorText: error });
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Final Assesment" />
      <Content>
        <div className="wide-lg mx-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (isFormValid) {
                formSubmit();
              } else {
                alert("Please answer all questions");
              }
            }}
          >
            <p className="fs-2 text-dark mb-5">Final Assesment ({courseDetail?.name})</p>
            {error.error ? (
              <div className="alert alert-danger d-flex align-items-center" role="alert">
                {" "}
                <em className="icon ni ni-alert-circle me-2"></em> <p>{error?.errorText?.message}</p>
              </div>
            ) : null}

            {courseTestQuestion?.length > 0 &&
              courseTestQuestion?.map((question, questionIndex) => (
                <React.Fragment key={questionIndex}>
                  <AssesmentQuestion
                    question={question}
                    index={questionIndex}
                    answers={formData[questionIndex]?.answers}
                    onChanged={handleOptionChange}
                  />
                </React.Fragment>
              ))}
            <button
              className="btn btn-primary btn-text custom-solid-btn-sm"
              type="submit"
              disabled={!isFormValid || isLoading || error.error}
              onClick={(e) => {
                e.preventDefault();
                if (courseDetail.isManualCertificate) {
                  setIsUploadCertificateVisiable(true);
                } else {
                  saveResult();
                }
              }}
            >
              {isLoading ? (
                <Spinner size="sm" color="light" />
              ) : courseDetail.isManualCertificate ? (
                "Upload certificate"
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </Content>
      <UploadManualCertificate
        visible={isUploadCertificateVisiable}
        trainingName={courseDetail?.name ?? ""}
        onClosed={() => {
          setIsUploadCertificateVisiable(false);
        }}
        onSuccess={(fileId) => {
          setIsUploadCertificateVisiable(false);
          saveResult(fileId);
        }}
      />
    </React.Fragment>
  );
};

export default AssesmentTest;

export const AssesmentQuestion = ({ question, answers, index, onChanged, allAnswers }) => {
  return (
    <div className="mb-4" key={`question-${index}`}>
      <p className="fs-5 text-dark  ">{question.description}</p>
      <div className="d-flex flex-column">
        {question?.options?.map((option, index) => (
          <div className="form-check bg-white rounded-3 mb-2" key={`option-${index}`}>
            <label className="form-check-label w-100  px-2 py-2 ">
              <input
                className="form-check-input"
                type={question.type === QuestionType.tf || question.type === QuestionType.mcq ? "radio" : "checkbox"}
                value={option}
                checked={question.type === QuestionType.msq ? allAnswers?.answer?.some((item) => item === index) : null}
                name={question?.description}
                onChange={(e) => {
                  onChanged(question.id, question, question?.options.indexOf(option));
                }}
              />
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
