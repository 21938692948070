import { RSelect } from "../../../components/Component";
import { locationsDropDown } from "./LocationData";
import React, { useEffect, useState } from "react";

export const LocationDropDown = ({
  value,
  isMulti = false,
  isShowAll = true,
  onSelect,
  placeholder,
  isFetchAll = false,
  disable,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    fetchLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLocations = () => {
    locationsDropDown(isFetchAll, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All Locations",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      isDisabled={disable}
      {...props}
    />
  );
};
