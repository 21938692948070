import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";

export const fetchCourseList = (search, locationId, callback) => {
  let params = {
    search: search,
    locationIds: locationId ? [locationId] : null,
  };

  apiService
    .postCall(APIServicePath.courseStats, params)
    .then((data) => {
      let list = ServerListToLocalCourseListItem(data.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const uploadCouseCover = (file, callback) => {
  apiService
    .fileUploader(file, "COURSE_COVER_IMAGE", APIServicePath.fileUpload)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const saveCourseDetail = (params, callback) => {
  apiService
    .postCall(APIServicePath.saveCourse, params, 120000)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getCourseDetail = (id, callback) => {
  apiService
    .postCall(APIServicePath.courseDetail, { id: id }, 60000)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const sendUnlockCourseRequest = (id, callback) => {
  apiService
    .postCall(APIServicePath.sendUnlockCourseRequest, { courseId: id })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

// Local Convert Function
const ServerListToLocalCourseListItem = (data) => {
  let list = [];
  for (let index = 0; index < data?.length ?? 0; index++) {
    const element = data[index];
    list.push(ServerToLocalCourseItem(element));
  }
  return list;
};

const ServerToLocalCourseItem = (data) => {
  let item = {
    id: data.id,
    name: data.name,
    average: data.avgCompliance ?? 0,
    total: data.totalCompliance ?? 0,
    totalStaff: data.totalStaff ?? 0,
    complaintStaff: data.totalComplianceStaff ?? 0,
  };

  return item;
};
