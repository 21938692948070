import React, { useEffect, useState } from "react";
import { Alert, Form, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewAltCard,
  RSelect,
} from "../../components/Component";
import DatePicker from "react-datepicker";
import { LocationDropDown } from "../dashboard/Location/LocationDropDown";
import { PermissionOption, StatusOption } from "./UserData";
import { RoleDropDown } from "../dashboard/Roles/RoleDropDown";
import { addStaff, editStaff } from "./StaffData";
import moment from "moment";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useLocation, useNavigate } from "react-router";
import { EMAIL_REGX, toCapitalize, UserAccountType } from "../utils";
import { Storage } from "../services/storage/storage";
import { ErrorText } from "../../components/text/Error";

export const AddStaff = () => {
  const [formData, setFormData] = useState({});
  const [isError, setIsError] = useState(false);
  const [errorVal, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = Storage.getLoggedInUser();

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // update formData object with data from location state if available
    if (location.state?.staff) {
      let homeLocation = location.state.staff?.homeLocation;
      let locationData = homeLocation
        ? {
            id: homeLocation.id,
            label: homeLocation.name,
            value: homeLocation.name,
          }
        : null;
      let role = location.state.staff?.role;
      let roleData = role
        ? {
            id: role.id,
            label: role.name,
            value: role.name,
          }
        : null;

      let managerLocation = [];
      if (location.state.staff?.managerAt) {
        for (let index = 0; index < location.state.staff?.managerAt?.length ?? []; index++) {
          const element = location.state.staff?.managerAt[index];
          managerLocation.push({
            id: element?.id,
            label: element?.name,
            value: element?.name,
          });
        }
      }

      let status = StatusOption[0];
      if (location.state.staff?.status && location.state.staff?.status !== "ACTIVE") {
        status = StatusOption[1];
      }

      setFormData({
        id: location.state.staff?.id,
        firstName: location.state.staff?.firstName,
        lastName: location.state.staff?.lastName,
        email: location.state.staff?.email,
        location: locationData,
        permission: {
          value: location.state.staff?.userType,
          label: toCapitalize(location.state?.staff?.userType ?? ""),
        },
        role: roleData,
        date: location.state.staff?.startDate ? moment(location.state.staff?.startDate, "YYYY-MM-DD").toDate() : null,
        status: status,
        managerAt: managerLocation,
        extraInfo: location.state.staff?.extraInfo,
      });
    } else {
      let permission = null;
      if (user?.accountType === UserAccountType.manager) {
        permission = PermissionOption[0];
      }
      setFormData({
        status: StatusOption[0],
        permission: permission,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSaveClick() {
    if (
      formData?.firstName === undefined ||
      formData?.firstName?.length === 0 ||
      formData?.lastName === undefined ||
      formData?.lastName?.length === 0 ||
      formData?.email === undefined ||
      formData?.email?.length === 0 ||
      EMAIL_REGX.test(formData?.email ?? "") === false ||
      formData?.location === undefined ||
      formData?.permission === undefined ||
      formData?.status === undefined ||
      formData?.role === undefined ||
      formData?.date === undefined ||
      (formData?.permission?.label === "Manager" &&
        (formData?.managerAt === undefined || formData?.managerAt?.length === 0))
    ) {
      setIsError(true);
    } else {
      setIsError(false);
      let params = {
        id: formData.id,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        roleId: formData.role.id,
        startDate: moment(formData.date).format("YYYY-MM-DD"),
        status: formData.status.value,
        homeLocationId: formData.location.id,
        userType: formData.permission.value,
        locationIds:
          formData?.permission?.value === "ADMIN"
            ? []
            : formData.managerAt?.filter((item) => item.id)?.map((item) => item.id),
        extraInfo: formData.extraInfo ?? "",
      };
      setLoading(true);
      if (formData.id && formData?.id > 0) {
        editStaff(params, (data, error) => {
          setLoading(false);
          if (error === null) {
            navigate(-1);
          } else {
            setError(error.message);
          }
        });
      } else {
        addStaff(params, (data, error) => {
          setLoading(false);
          if (error === null) {
            navigate(-1);
          } else {
            setError(error.message);
          }
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Head title="add-staff" />
      <Content>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> Unable to login with credentials{" "}
            </Alert>
          </div>
        )}
        <Block className={"justify-center"}>
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {location.state?.staff ? "Edit Staff" : "Add Staff"}
              </BlockTitle>
              <PreviewAltCard>
                <Form className="row gy-4">
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">First name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        value={formData?.firstName ?? ""}
                        placeholder="e.g. Jane"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                      />
                      {isError && (formData.firstName === undefined || formData.firstName?.length === 0) && (
                        <span className="invalid">This field is required</span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Last name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        value={formData?.lastName ?? ""}
                        placeholder="e.g. Doe"
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                      />
                      {isError && (formData.lastName === undefined || formData.lastName?.length === 0) && (
                        <span className="invalid">This field is required</span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        value={formData?.email ?? ""}
                        disabled={
                          user?.accountType !== UserAccountType.admin &&
                          user?.accountType !== UserAccountType.manager &&
                          location.state?.staff !== undefined
                        }
                        placeholder="e.g. jane@example.com"
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      {isError &&
                        (formData.email === undefined || EMAIL_REGX.test(formData?.email ?? "") === false) && (
                          <span className="invalid">This field is required</span>
                        )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Home location</label>
                      <div className="form-control-wrap">
                        <LocationDropDown
                          value={formData?.location}
                          isMulti={false}
                          isShowAll={false}
                          isFetchAll={true}
                          placeholder={"Select e.g. Nursing Care"}
                          onSelect={(e) => setFormData({ ...formData, location: e })}
                        />
                        {isError && formData.location === undefined && <ErrorText />}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Permission</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={PermissionOption}
                          isDisabled={user?.accountType !== UserAccountType.admin}
                          value={formData?.permission ?? ""}
                          placeholder={"Select e.g. Learner"}
                          onChange={(e) => setFormData({ ...formData, permission: e })}
                        />
                        {isError && formData.permission === undefined && <ErrorText />}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    {formData.permission?.label === "Manager" && (
                      <div className="form-group">
                        <label className="form-label">Manager at</label>
                        <div className="form-control-wrap">
                          <LocationDropDown
                            value={formData?.managerAt}
                            isMulti={true}
                            isShowAll={false}
                            placeholder={"Select your manage locations"}
                            onSelect={(e) => setFormData({ ...formData, managerAt: e })}
                          />
                          {isError && formData.managerAt === undefined && <ErrorText />}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Role</label>
                      <div className="form-control-wrap">
                        <RoleDropDown
                          value={formData?.role ?? ""}
                          placeholder={"Select role e.g. HCA"}
                          // isDisabled={location.state?.staff !== undefined}
                          onSelect={(e) => setFormData({ ...formData, role: e })}
                        />
                        {isError && formData.role === undefined && <ErrorText />}
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={StatusOption}
                          value={formData?.status ?? StatusOption[0]}
                          onChange={(e) => setFormData({ ...formData, status: e })}
                        />
                        {isError && formData.status === undefined && <ErrorText />}
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <label className="form-label">Start date </label>
                      <div className="form-control-wrap">
                        <div className="position-relative">
                          {/* <em className="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                          {/* <em class="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                          <DatePicker
                            toggleCalendarOnIconClick
                            className="form-control date-picker"
                            selected={formData?.date ?? ""}
                            dateFormat={"dd-MM-yyyy"}
                            placeholderText="e.g. 22-03-2023"
                            onChange={(date) => setFormData({ ...formData, date: date })}
                          />
                        </div>
                        {isError && formData.date === undefined && <ErrorText />}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label className="form-label">Extra info (optional)</label>
                      <textarea
                        className="form-control"
                        id="extraInfo"
                        name="extraInfo"
                        value={formData?.extraInfo ?? ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            extraInfo: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="md"
                          type="button"
                          className="custom-solid-btn-sm"
                          onClick={() => onSaveClick()}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : "Save"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            navigate(-1);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </PreviewAltCard>
            </BlockHeadContent>
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};
