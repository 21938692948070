import React from "react";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button } from "../../../components/Component";
import ClientTraining from "./ClientTraining";

const TrainingSlider = ({ title, list, isMandatory, onViewAllClick }) => {
  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              {title}
            </BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent>
            <Button color="primary" className="btn-text custom-solid-btn-sm" onClick={onViewAllClick}>
              View all
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <ClientTraining data={list} isMandatory={isMandatory} />
      </Block>
    </React.Fragment>
  );
};

export default TrainingSlider;
