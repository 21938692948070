import React, { useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  UserAvatar,
} from "../../../components/Component";

import Content from "../../../layout/content/Content";
import { downloadCertificate, staffGetCertificateList } from "./CertificateData";
import { LocationDropDown } from "../Location/LocationDropDown";
import moment from "moment";
import LoadingComponent from "../../../components/spinner/LoadingSpinner";
import { openLinkInNewTab, showErrorToast, toCapitalize } from "../../utils";
import { ColorsArray, findUpper } from "../../utils/Utils";

const CertificateList = () => {
  const locationState = useLocation();
  const [location, setLocation] = useState(locationState.state?.location ?? null);
  const [certificates, setCertificates] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(certificates?.length);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    certificateList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setTotalItems(certificates?.length || 0);
    // Update totalItems when certificates changes
  }, [certificates]);

  function certificateList() {
    const startDate = moment().subtract(1, "weeks").startOf("isoWeek").toDate();
    const endDate = moment().subtract(1, "weeks").endOf("isoWeek").toDate();

    setStartDate(startDate);
    setEndDate(endDate);

    let params = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      locationIds: location ? [location.id] : [],
    };
    staffGetCertificateList(params, (data, error) => {
      if (error === null) {
        setCertificates(data.list || []);
      }

      setIsInitScreen(false);
    });
  }
  function handleDownloadAllClick(ev) {
    let params = {
      isMyCertificates: false,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      locationIds: location ? [location.id] : [],
    };
    setIsLoading(true);
    downloadCertificate(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.downloadUrl, ev);
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  }

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onDonwloadClick = (item, event) => {
    openLinkInNewTab(item.url, event);
  };

  return (
    <React.Fragment>
      <Head title="certificate-list"></Head>
      {!isInitScreen && (
        <Content>
          <BlockHead>
            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center ">
              <BlockHeadContent className={"mb-2 mb-md-0"}>
                <BlockTitle tag="h3" page>
                  {certificates?.length ?? 0} new certificates
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>
                    The list below shows record from {moment(startDate).format("DD-MM-YYYY")} to{" "}
                    {moment(endDate).format("DD-MM-YYYY")}{" "}
                  </p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="primary"
                  className="btn-icon ps-2 pe-2 custom-solid-btn-sm"
                  onClick={handleDownloadAllClick}
                  disabled={(certificates?.length ?? 0) === 0 || isLoading}
                >
                  {isLoading ? <Spinner size="sm" color="light" /> : "Download all certificate"}
                </Button>
              </BlockHeadContent>
            </div>
          </BlockHead>
          <Block>
            <DataTable className="card-stretch certificate-list-table">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <LocationDropDown
                          value={location}
                          className="w-250px"
                          isMulti={false}
                          onSelect={(e) => {
                            setLocation(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">Staff name</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Training name</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Issued</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text"></span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {certificates?.length > 0
                  ? certificates.map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow>
                            <div className="user-card">
                              {/* <UserAvatar
                                 theme={item.avatarBg}
                                 text={findUpper(item.name)}
                                 image={item.image}
                               ></UserAvatar> */}
                              <UserAvatar
                                theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                                text={
                                  findUpper(toCapitalize(item?.user?.firstName ?? "")) +
                                  findUpper(toCapitalize(item?.user?.lastName ?? ""))
                                }
                                image={item?.image}
                              ></UserAvatar>

                              <div className="user-info">
                                <span className="tb-lead mt-1">
                                  {toCapitalize(item?.user?.firstName ?? "") +
                                    " " +
                                    toCapitalize(item?.user?.lastName ?? "")}
                                </span>
                                <p className="text-gray">{item?.user?.homeLocation?.name ?? ""}</p>
                              </div>
                              {/* <div className="user-info">
                                <span className="tb-lead mt-1">
                                  {toCapitalize(item.name)}
                                  {""}
                                </span>
                                <p className="text-gray">{item?.location}</p>
                              </div> */}
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{item.name}</span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{moment(item?.issueAt ?? "").format("DD-MM-YYYY")} </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow className={"text-end"}>
                            <Button className="btn-text" color="" onClick={(ev) => onDonwloadClick(item, ev)}>
                              <span className="fw-bold align-center">
                                PDF
                                <Icon className={"ms-1"} name="download" />
                              </span>
                            </Button>
                            <div className="user-info"></div>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {totalItems > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>
        </Content>
      )}

      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default CertificateList;
