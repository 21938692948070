import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import Privacy from "../pages/others/Privacy";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ConfirmationPage from "../pages/auth/Confirmation";
import VerifyPassword from "../pages/auth/CheckPassword";
import CreatePassword from "../pages/auth/CreatePassword";

import Dashboard from "../pages/dashboard/Dashboard";
import Stafflist from "../pages/Stafflist/Stafflist";
import Facetoface from "../pages/Facetoface/Facetoface";
import Policies from "../pages/Policies/Policies";
import Resources from "../pages/Resources/Resources";
import Roleslist from "../pages/dashboard/Roles/Roleslist";
import LocationList from "../pages/dashboard/Location/Location";
import TrainingList from "../pages/dashboard/Training/TrainingList";
import CertificateList from "../pages/dashboard/Certificate/CertificateList";
import StaffTraining from "../pages/Stafflist/Stafftraining";
import RoleWizard from "../pages/dashboard/Roles/RoleWizard";
import FaceToFaceDetail from "../pages/Facetoface/DateEvent";
import CourseWizard from "../pages/dashboard/Training/WizardForms/CourseWizard";
import AddPolicy from "../pages/Policies/AddPolicy";
import TrainingWizard from "../pages/Stafflist/TrainingWizard";
import CourseMatrix from "../pages/matrix/CourseMatrix";
import { AddStaff } from "../pages/Stafflist/AddStaff";
import ClientDashboard from "../pages/dashboard/Client-Training/ClientDashboard";
import ViewAllCourse from "../pages/dashboard/Client-Training/ViewAllCourse";
import LearnerFaceToFace from "../pages/Facetoface/LearnerFaceToFace";
import LearnerPolicies from "../pages/Policies/LearnerPolicies";
import PolicyView from "../pages/Policies/PolicyView";
import StartCourse from "../pages/dashboard/Client-Training/StartCourse";
import StartCourseDetails from "../pages/dashboard/Client-Training/StartCourseDetails";
import AssesmentTest from "../pages/dashboard/Client-Training/AssesmentTest";
import TrainingCertificate from "../pages/dashboard/Client-Training/TrainingCertificate";
import PolicyList from "../pages/Policies/PolicyList";
import GeneratedCertificate from "../pages/dashboard/Certificate/GeneratedCertificate";
import Result from "../pages/dashboard/Client-Training/Result";
import ResourcesFolder from "../pages/Resources/ResourcesFolder";
import NewAddResources from "../pages/Resources/NewAddResources";
import Support from "../pages/support/Support";
import AddTicket from "../pages/support/AddTicket";
import EvalutionForm from "../pages/dashboard/Client-Training/EvalutionForm";
import CPD from "../pages/dashboard/Client-Training/CPD";
import ArchiveCertificate from "../pages/dashboard/Certificate/ArchiveCertificate";
import PendingCertificate from "../pages/dashboard/Certificate/PendingCertificate";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import { UserAccountType } from "../pages/utils";
import { Storage } from "../pages/services/storage/storage";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let user = Storage.getLoggedInUser();

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
        <Route path="_blank" element={<Blank />}></Route>
        {user?.accountType === UserAccountType.staff ? (
          <>
            <Route exact path={`${process.env.PUBLIC_URL}/`} element={<ClientDashboard />}></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/resources`} element={<Resources />}></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/cpd-list`} element={<CPD />}></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/resource-folders`} element={<ResourcesFolder />}></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/learner-face-to-face`}
              element={<LearnerFaceToFace />}
            ></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/learner-policies`} element={<LearnerPolicies />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/policy-view`} element={<PolicyView />}></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/view-all-course`} element={<ViewAllCourse />}></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/start-course`} element={<StartCourse />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/check-password`} element={<VerifyPassword />}></Route>

            <Route exact path={`${process.env.PUBLIC_URL}/change-password`} element={<CreatePassword />}></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/start-course-details`}
              element={<StartCourseDetails />}
            ></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/course-assesment-test`} element={<AssesmentTest />}></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-certificate`}
              element={<TrainingCertificate />}
            ></Route>
            <Route path={`${process.env.PUBLIC_URL}/training-certificate`} element={<TrainingCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/user-certificate`} element={<GeneratedCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pending-certificate`} element={<PendingCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/user-archive-certificate`} element={<ArchiveCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/training-assesment-result`} element={<Result />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/face-to-face-detail`} element={<FaceToFaceDetail />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/evaluation-form`} element={<EvalutionForm />}></Route>
          </>
        ) : (
          <>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Dashboard />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/staff`} element={<Stafflist />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/policy-list`} element={<PolicyList />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/staff-training`} element={<StaffTraining />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/manage-face-to-face`} element={<Facetoface />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/face-to-face-detail`} element={<FaceToFaceDetail />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/policies`} element={<Policies />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/add-policy`} element={<AddPolicy />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/resources`} element={<Resources />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/resource-folders`} element={<ResourcesFolder />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/roles-list`} element={<Roleslist />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/role`} element={<RoleWizard />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/add-staff`} element={<AddStaff />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/matrix`} element={<CourseMatrix />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/training-list`} element={<TrainingList />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/training-wizard`} element={<TrainingWizard />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/certificate-list`} element={<CertificateList />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/location`} element={<LocationList />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/client-dashboard`} element={<ClientDashboard />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/check-password`} element={<VerifyPassword />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/course-wizard`} element={<CourseWizard />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/training-certificate`} element={<TrainingCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/policy-view`} element={<PolicyView />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/user-certificate`} element={<GeneratedCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/pending-certificate`} element={<PendingCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/user-archive-certificate`} element={<ArchiveCertificate />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/training-assesment-result`} element={<Result />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/add-resources`} element={<NewAddResources />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/support`} element={<Support />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/add-ticket`} element={<AddTicket />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/evaluation-form`} element={<EvalutionForm />}></Route>
          </>
        )}
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        {/* AUTH PATH */}
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ForgotPassword />}></Route>
        <Route path="confirmation" element={<ConfirmationPage />}></Route>
        <Route path="check-password" element={<VerifyPassword />}></Route>
        <Route path="change-password" element={<CreatePassword />}></Route>
        <Route path="auth-login" element={<Login />}></Route>
        <Route path="terms-condition" element={<Terms />}></Route>
        <Route path="help" element={<Faq />}></Route>
        <Route path="privacy" element={<Privacy />}></Route>

        {/* DASHBOARD PATH */}

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
