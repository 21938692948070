import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import {
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../components/Component";
import { archiveCertificateAPI } from "./StaffData";
import moment from "moment";
import { openLinkInNewTab } from "../utils";

const ArchiveCertificateModal = ({ visible, item, staffId, onClosed }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (visible && item) {
      loadArchiveCertificateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  function loadArchiveCertificateList() {
    let params = {
      userId: staffId,
      courseId: item?.id,
    };
    archiveCertificateAPI(params, (data, error) => {
      if (error === null) {
        setData(data?.list);
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  const handleFormSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      onClosed();
    }, 1000);
  };

  return (
    <Modal
      isOpen={visible}
      toggle={onClosed} // Use onClosed directly for toggle
      className="modal-lg add-face-event-modal modal-dialog-scrollable"
    >
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">{item?.name}</p>
      </ModalHeader>
      <ModalBody>
        <div className="">
          {isLoading ? (
            <Spinner size="sm" color="dark" />
          ) : (
            <DataTable className="card-stretch">
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="">Date</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Generated Type</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Generated By</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text"></span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {(data?.length ?? 0) > 0
                  ? data?.map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{moment(item.issueAt).format("DD-MM-YYYY")}</span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{item?.generatedType ?? "N/A"}</span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">
                                {(item?.generatedBy?.firstName ?? "N/A") + " " + (item?.generatedBy?.lastName ?? "")}
                              </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow className={"text-end"}>
                            <Button
                              className="btn-text"
                              color=""
                              onClick={(ev) => {
                                openLinkInNewTab(item.url, ev);
                              }}
                            >
                              <span className="fw-bold align-center">
                                PDF
                                <Icon className={"ms-1"} name="download" />
                              </span>
                            </Button>
                            <div className="user-info"></div>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              {data?.length === 0 && (
                <div className="card-inner">
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                </div>
              )}
            </DataTable>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ArchiveCertificateModal;
