/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Col, Row } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import VideoPlay from "./VideoPlay";
import { useLocation, useNavigate } from "react-router-dom";
import { iconsType } from "./ResourceData";
import { openLinkInNewTab } from "../utils";

const Resources = () => {
  const [resourceData, setResourceData] = useState([]);
  const [modal, setModal] = useState({
    addResource: false,
    video: false,
  });
  const [videoId, setVideoId] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  // Get current list, pagination
  useEffect(() => {
    if (location.state?.resource) {
      setResourceData(location.state?.resource);
    } else {
      navigate(`${process.env.PUBLIC_URL}/resource-folders`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const playVideo = (Id) => {
    setVideoId(Id);
    setModal({ video: true });
  };
  const truncate = function (fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || ". . . . . . ";

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
  };

  return (
    <React.Fragment>
      <Head title="Resources" />
      <Content>
        <div className="wide-md mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {resourceData.title}
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            {resourceData?.link?.map((item, index) => (
              <Block key={index}>
                <p className="fs-4">{item.title}</p>
                <Row>
                  {item?.list?.map((link, index) => {
                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                    var match = link?.url?.match(regExp);
                    if (match) {
                      const videoId = getId(link?.url);
                      return (
                        <React.Fragment key={`video-${index}`}>
                          <Col className="col-6 mb-4" md="4" lg="3" xxl="2">
                            <div className="d-block">
                              <div className="position-relative" onClick={() => playVideo(videoId)}>
                                <img src={`//img.youtube.com/vi/${videoId}/0.jpg`}></img>
                                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-tranperent-black d-flex align-items-end ps-3 pb-3">
                                  <span className="d-flex align-items-center justify-content-center text-white position-absolute cursor-pointer">
                                    <span className="ni ni-play me-1 fs-3"></span>
                                    <span>Play Video</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </React.Fragment>
                      );
                    }
                  })}
                </Row>
                {item?.list?.some(
                  (item) =>
                    !item?.url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/)
                ) ? (
                  <Block>
                    <Row>
                      {(item?.list?.length ?? 0) > 0 &&
                        item?.list?.map((item, index) => {
                          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                          var match = item?.url?.match(regExp);
                          if (!match) {
                            return (
                              <React.Fragment key={`link-${index}`}>
                                <Col className="col-12">
                                  <div
                                    className="d-block p-2 bg-gray-dim rounded mb-1 cursor-pointer"
                                    onClick={(ev) => {
                                      openLinkInNewTab(item.url, ev);
                                    }}
                                  >
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href={undefined} target="_blank" className="word-break" rel="noreferrer">
                                      {item?.url}
                                    </a>
                                  </div>
                                </Col>
                              </React.Fragment>
                            );
                          }
                        })}
                    </Row>
                  </Block>
                ) : null}
              </Block>
            ))}
            {resourceData?.resourceGroup?.map((item) => (
              <Block>
                <p className="fs-4">{item.title}</p>
                <Row>
                  {(item?.files?.length ?? 0) > 0 &&
                    item?.files.map((item, index) => (
                      <React.Fragment key={`${index}`}>
                        <Col className="col-6 mb-4" md="4" lg="3" xxl="2">
                          <div
                            className="nk-file-item nk-file bg-white rounded p-4 cursor-pointer"
                            onClick={(ev) => {
                              openLinkInNewTab(item?.fileUrl, ev);
                            }}
                          >
                            <div className="nk-file-info">
                              <div className="nk-file-title d-flex flex-column">
                                <div className="nk-file-icon mb-3">
                                  <span className="nk-file-icon-type w-72">
                                    {iconsType[item.mimeType] ? iconsType[item.mimeType] : iconsType["others"]}
                                  </span>
                                </div>
                                <div className="nk-file-name mb-3">
                                  <div className="nk-file-name-text p-0">
                                    <span className="title">{truncate(item.name, 25)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              </Block>
            ))}
          </Block>

          <VideoPlay
            visible={modal.video}
            item={videoId}
            onClosed={(status) => {
              setModal({ video: false });
            }}
          />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Resources;
