import React, { useRef, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button } from "../../../components/Component";
import CPDList from "../../Stafflist/CPDList";
import AddCpd from "../../Stafflist/AddCPD";
import { Storage } from "../../services/storage/storage";

const LearnerCPD = () => {
  const [cpdModal, setCpdModal] = useState(false);
  const cpdListRef = useRef();
  const user = Storage.getLoggedInUser(); // Call the function to get the user data

  return (
    <React.Fragment>
      <Head title="CPD" />
      <Content>
        <div className="wide-md mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  CPD
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="primary"
                  className="d-sm-inline-flex custom-solid-btn-sm"
                  onClick={() => setCpdModal(true)}
                >
                  Add CPD Training
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <CPDList ref={cpdListRef} staffId={user?.id} isLearnerSide={true} />
          </Block>
        </div>
      </Content>
      <AddCpd
        visible={cpdModal}
        isAddTraining={true}
        onClosed={() => {
          setCpdModal(false);
        }}
        onSuccess={() => {
          cpdListRef.current.reloadCPDList();
        }}
      />
    </React.Fragment>
  );
};

export default LearnerCPD;
