import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Col } from "../../components/Component";
import { getRoleList } from "../dashboard/Roles/RolesData";

const SetRolesFilter = ({ visible, onChanged, onClosed, selectedRoles }) => {
  const [rolesData, setRolesData] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    let list = rolesData.filter((e) => e.checked === true);
    onChanged(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData]);

  function loadRoles() {
    getRoleList(false, (rolesData, error) => {
      if (error === null) {
        setRolesData(rolesData);
      }
      setIsInitScreen(false);
    });
  }
  useEffect(() => {
    if (visible) {
      if (rolesData) {
        setRolesData((prevData) =>
          prevData.map((item) =>
            selectedRoles?.some((e) => e.id === item.id) ? { ...item, checked: true } : { ...item, checked: false }
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onSelectChange = (e, id) => {
    const newState = e.currentTarget.checked;
    setRolesData((prevData) => prevData.map((item) => (item.id === id ? { ...item, checked: newState } : item)));
  };

  return (
    <>
      <Modal isOpen={visible} toggle={() => onClosed()} className="modal-dialog-centered role-filter" size="md">
        <ModalHeader className="bg-purple text-white" toggle={onClosed}>
          {" Roles"}
        </ModalHeader>
        <ModalBody>
          <Form className="row gy-3">
            {rolesData.map((item, index) => (
              <Col lg="4" xxl="3" md="6" sm="12" key={index}>
                <div className="form-group">
                  <span className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`pid-${item.id}`}
                        checked={item?.checked ?? false}
                        onChange={(e) => onSelectChange(e, item.id)}
                      />
                      <label className="custom-control-label" htmlFor={`pid-${item.id}`}>
                        {item.role}
                      </label>
                    </div>
                  </span>
                </div>
              </Col>
            ))}
          </Form>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={() => onClosed()}>
            Save
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default SetRolesFilter;
