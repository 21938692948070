import { findUpper } from "../../utils/Utils";
import { UserAvatar } from "../../components/Component";
import moment from "moment";
import { memo } from "react";
import { iconsType } from "../Resources/ResourceData";

const ReplyItem = ({ item }) => {
  const { note } = item;
  const theme = "purple";
  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  let createdAt = item?.createdAt;
  let today = moment(new Date()).format("YYYY-MM-DD");
  let createdAtDate = moment(createdAt).format("YYYY-MM-DD");
  let displayDateTime;
  if (today === createdAtDate) {
    displayDateTime = moment(createdAt).format("HH:mm");
  } else {
    displayDateTime = moment(createdAt).format("D MMM HH:mm");
  }

  return (
    <div className="nk-reply-item">
      <div className="nk-reply-header">
        <div className="user-card">
          <UserAvatar size="sm" theme={theme} text={findUpper(item?.userName ?? "")} />

          <div className="user-name">{item?.userName}</div>
        </div>
        <div className="date-time">{displayDateTime}</div>
      </div>
      <div className="nk-reply-body">
        <div className={`nk-reply-entry entry ${note ? "note" : ""}`}>
          {item?.desc}
          {item?.files && item?.files?.length > 0 ? (
            <div className="nk-upload-files-filelist">
              {item?.files.map((file, index) => (
                <div className="nk-upload-item-filelist" key={index}>
                  <div className="nk-upload-icon">
                    {file?.mimeType ? (
                      <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                    ) : (
                      <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                    )}
                  </div>
                  <div className="nk-upload-info">
                    <div className="nk-upload-title">
                      <span className="title" style={{ cursor: "pointer" }} onClick={() => openFileInNewTab(file)}>
                        {file?.name ? file?.name : file?.fileName ? file?.fileName : "Ticket"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(ReplyItem);
