import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect } from "../../components/Component";
import { FrequencyOptions } from "../dashboard/Roles/RolesData";
import { useEffect } from "react";
import moment from "moment";
import { staffcourseupdate, uploadCertificateFile } from "./StaffData";
import { iconsType } from "../Resources/ResourceData";
import { Storage } from "../services/storage/storage";
import { AppText, dueDate, showErrorToast, UserAccountType } from "../utils";
import { bytesToMegaBytes } from "../utils/Utils";
import { ErrorText } from "../../components/text/Error";

const EditStaffTraining = ({ visible, onClosed, dataInfo, staffUser, onChange }) => {
  const [completedAt, setCompletedAt] = useState(new Date());
  const [dueDateAt, setDueDateAt] = useState(new Date());
  const [frequency, setFrequency] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const { handleSubmit } = useForm();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = Storage.getLoggedInUser();

  useEffect(() => {
    if (dataInfo !== undefined) {
      let completedate = dataInfo.completedAt;
      let nextDate = dataInfo.maxDueAt;
      const dateStart = completedate ? new Date(completedate) : null;
      const reviseDate = nextDate ? new Date(nextDate) : new Date();
      setCompletedAt(dateStart);
      setDueDateAt(reviseDate);
      let list = FrequencyOptions.filter((item) => item.value === dataInfo.frequency);
      if (list.length > 0) {
        setFrequency(list[0]);
      }
    }
  }, [dataInfo]);

  const handleFormSubmit = () => {
    // if isManualCertificate = true, attachement is requires field
    if (dataInfo?.isManualCertificate && !selectedFile) {
      setIsError(true);
      return;
    }

    if (completedAt !== undefined && dueDateAt !== undefined && frequency !== undefined) {
      const completedDate = completedAt ? moment(completedAt).format("YYYY-MM-DD") : undefined;
      const maxDueAt = dueDateAt ? moment(dueDateAt).format("YYYY-MM-DD") : undefined;

      let params = {
        userId: staffUser,
        courseId: dataInfo?.id,
        completedDate: completedDate,
        maxDueAt: completedDate && frequency?.value === "ONE_OFF" ? null : maxDueAt,
        frequency: frequency?.value,
        fileId: selectedFile?.id,
      };
      editStaffTraining(params);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (visible) {
    } else {
      setIsLoading(false);
      setIsError(false);
      setSelectedFile();
    }
  }, [visible]);

  function editStaffTraining(params) {
    setIsLoading(true);
    staffcourseupdate(params, (data, error) => {
      if (error === null) {
        setIsLoading(false);
        onClosed(data);
        onChange();
      } else {
        console.log(error);
      }
    });
  }

  const handleFileSelect = (file) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;
      setSelectedFile(fileDetails);
      // for API
      uploadFile(fileDetails);
    }
  };

  const uploadFile = (file) => {
    uploadCertificateFile(file, "MANUAL_COURSE_CERTIFICATE", (data, error) => {
      if (error === null) {
        let object = {};
        object.id = data.id;
        object.isLoading = false;
        object.name = file.name;
        object.type = file.type;
        object.size = file.size;
        object.fileUrl = data.fileUrl;
        setSelectedFile(object);
      } else {
        setSelectedFile();
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  return (
    <>
      <Modal isOpen={visible} toggle={() => onClosed()} className="modal-md edit-staff-training">
        <ModalHeader className="bg-purple text-white" toggle={onClosed}>
          <p className="title">Edit training</p>
        </ModalHeader>
        <ModalBody>
          <div className="p-2">
            <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Completed Date</label>
                  <div className="position-relative">
                    {/* <em className="ni fs-5 ni-calender-date position-absolute calender-input-position"></em> */}
                    <DatePicker
                      toggleCalendarOnIconClick
                      selected={completedAt}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      onChange={(date) => {
                        setCompletedAt(date);
                        let result = dueDate(frequency.value, date);
                        setDueDateAt(result);
                      }}
                      // minDate={new Date()}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Due Date</label>
                  <div className="position-relative">
                    <DatePicker
                      selected={dueDateAt ?? new Date()}
                      className="form-control "
                      dateFormat={"dd-MM-yyyy"}
                      onChange={(date) => {
                        setDueDateAt(date);
                        // Apply only if course/traning is completed
                        // if (completedAt) {
                        //   let result = completedDate(frequency.value, date);
                        //   setCompletedAt(result);
                        // }
                      }}
                      // minDate={new Date()}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="form-label">Renewal frequency</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={FrequencyOptions}
                      value={frequency}
                      isDisabled={user?.accountType !== UserAccountType.admin}
                      onChange={(selectedOption) => {
                        setFrequency(selectedOption);
                        // if (completedAt) {
                        //   let result = dueDate(selectedOption.value, completedAt);
                        //   setDueDateAt(result);
                        // }
                      }}
                    />
                  </div>
                </div>
              </Col>
              {dataInfo?.isManualCertificate ? (
                <Col>
                  <div className="form-group mb-0 mt-n1">
                    <label htmlFor={"files"}>
                      <Icon name="clip" className="mb-1 text-primary" />
                      <span className="text-primary"> Attach your certificate</span>
                    </label>
                    <input
                      type="file"
                      name="files"
                      accept=".pdf,.doc,.docx"
                      className="form-control"
                      id={"files"}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let temp = Array.prototype.slice.call(e.target.files);
                        if (temp.length > 0) {
                          handleFileSelect(temp[0]);
                        }
                      }}
                    />
                    {!selectedFile && isError ? <ErrorText /> : null}
                  </div>

                  {selectedFile ? (
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {iconsType[selectedFile.type] ? iconsType[selectedFile.type] : iconsType["others"]}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span className="title">{selectedFile.name}</span>
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {selectedFile.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span
                              onClick={() => {
                                setSelectedFile();
                              }}
                            >
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  ) : null}
                </Col>
              ) : null}

              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" className="custom-solid-btn-sm" size="md" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" color="dark" /> : "Save"}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditStaffTraining;
