/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppWrap from "./global/AppWrap";

import FileManagerProvider from "../pages/app/file-manager/components/Context";

const Layout = ({ title, ...props }) => {
  const [visibility, setVisibility] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [themeState] = useState({
    main: "default",
    header: "white",
    skin: "light",
  });

  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  return (
    <FileManagerProvider>
      <Head title={!title && "Loading"} />
      <AppRoot>
        <AppWrap>
          <Header
            visibility={visibility}
            toggleSidebar={toggleSidebar}
            mobileView={mobileView}
            theme={themeState.header}
            fixed={true}
          />

          <Outlet />
          <Footer />
        </AppWrap>
      </AppRoot>
    </FileManagerProvider>
  );
};
export default Layout;
