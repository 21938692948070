import { apiService } from "../services/api";
import { APIServicePath } from "../services/api/api-service-impl";

export function fetchStaff(pageDetails, callback) {
  let params = {
    pageInfo: {
      pageIndex: pageDetails.page,
      itemsPerPage: pageDetails.itemPerPage,
    },
  };
  apiService
    .postCall(APIServicePath.staffList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function sendCourseMatrixEmail(params, callback) {
  apiService
    .postCall(APIServicePath.courseMatrixEmail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export const getCourseyMatrix = (params, token, callback) => {
  apiService
    .postCallWithCancelSignal(APIServicePath.courseMatrix, params, token)
    .then((data) => {
      let userData = [];
      data?.list?.map((userItem, index) => {
        let user = {
          firstName: userItem.firstName,
          userid: userItem.id,
          lastName: userItem.lastName,
          courses: userItem?.courses,
          compliancePercentage: userItem?.compliancePercentage,
          accountType: userItem?.userType,
        };
        return userData.push(user);
      });
      callback({ userdata: userData, pageinfo: data.pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchSendEmail = (parms, callback) => {
  apiService
    .postCall(APIServicePath.sendCourseMatrixToEmail, parms)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const coursedata = [
  { id: -1, corurseName: "users" },
  { id: 0, corurseName: "Percentage" },
  { id: 1, corurseName: "Confidentiality" },
  { id: 2, corurseName: "Record keeping and Data Protection" },
  { id: 3, corurseName: "Acquired Brain Injury" },
  { id: 4, corurseName: "Adult Safeguarding Northern Ireland (Level 1)" },
  { id: 5, corurseName: "Anaphylaxis Management: Bitesize Learning" },
  { id: 6, corurseName: "Basic Life Support (Theory)" },
  { id: 7, corurseName: "Bed Rail Safety: Bitesize Learning" },
  {
    id: 8,
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  { id: 9, corurseName: "users" },
  { id: 10, corurseName: "Percentage" },
  { id: 11, corurseName: "Confidentiality" },
  { id: 12, corurseName: "Record keeping and Data Protection" },
  { id: 13, corurseName: "Acquired Brain Injury" },
  { id: 14, corurseName: "Adult Safeguarding Northern Ireland (Level 1)" },
  { id: 15, corurseName: "Anaphylaxis Management: Bitesize Learning" },
  { id: 16, corurseName: "Basic Life Support (Theory)" },
  { id: 17, corurseName: "Bed Rail Safety: Bitesize Learning" },
  {
    id: 18,
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
];
export const userdata = [
  {
    id: 1,
    email: "abc@gmail.com",
    name: "abc",
    corurseName: "Confidentiality",
    percentage: 90,
    courses: [
      {
        id: 1,
        date: "23/04/2023",
      },
      {
        id: 6,
        date: "04/04/2023",
      },
    ],
  },
  {
    id: 2,
    email: "def@gmail.com",
    name: "def",
    corurseName: "Record keeping and Data Protection",
  },
  {
    id: 3,
    email: "ghi@gmail.com",
    name: "ghi",
    corurseName: "Acquired Brain Injury",
  },
  {
    id: 4,
    email: "jkl@gmail.com",
    name: "jkl",
    corurseName: "Adult Safeguarding Northern Ireland (Level 1)",
  },
  {
    id: 5,
    email: "mno@gmail.com",
    name: "mno",
    corurseName: "Anaphylaxis Management: Bitesize Learning",
  },
  {
    id: 6,
    email: "pqr@gmail.com",
    name: "pqr",
    corurseName: "Basic Life Support (Theory)",
  },
  {
    id: 7,
    email: "stu@gmail.com",
    name: "stu",
    corurseName: "Bed Rail Safety: Bitesize Learning",
  },
  {
    id: 8,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 9,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 10,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 11,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 12,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 13,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 14,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 15,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 16,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 17,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
  {
    id: 18,
    email: "vwx@gmail.com",
    name: "vwx",
    corurseName: "Building Mental Health Resilience: Bitesize Learning",
  },
];
