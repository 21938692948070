import React from "react";
import { Block } from "../../../components/Component";
import { Card } from "reactstrap";
import { TrainingCard } from "./TrainingCard";
import Slider from "react-slick";
import { SlickArrowLeft, SlickArrowRight } from "./SlideRightLeft";

const ClientTraining = ({ data, isMandatory }) => {
  const sliderSettings = {
    className: "slider-init row",
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    arrows: data.length === 1 ? false : true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      { breakpoint: 3000, settings: { slidesToShow: 3 } },
      { breakpoint: 1302, settings: { slidesToShow: 2 } },
      { breakpoint: 687, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <Block>
      {(data?.length ?? 0) > 0 && (
        <Slider {...sliderSettings}>
          {data.map((item, index) => {
            return (
              <div className="user-training-course-details max-w-512" key={index}>
                <Card className="ms-3 me-3">
                  <TrainingCard item={item} isMandatory={isMandatory} />
                </Card>
              </div>
            );
          })}
        </Slider>
      )}
    </Block>
  );
};

export default ClientTraining;
